// 中英文切换
import Vue from 'vue';
// import ElementUI from 'element-ui';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

import zhcnLocale from 'element-ui/lib/locale/lang/zh-CN';
import enLocale from 'element-ui/lib/locale/lang/en';

zhcnLocale.el.pagination = {
    goto: '前往',
    pagesize: '/页',
    total: `共 {total} 条`,
    pageClassifier: '页',
};

enLocale.el.pagination = {
    goto: 'Go to',
    total: `totally {total} item`,
    pagesize: '/page',
    pageClassifier: ''
};
// Vue.use(ElementUI, {
//     // size: 'small',
//     locale: {
//         el: {
//             // 整体覆盖
//             ...enLocale.el,
//             pagination: {
//                 pagesize: '/page',
//                 total: `totally {total} items`,
//                 goto: 'Go to',
//                 pageClassifier: ''
//             },
//         }
//     }
// })

import nextEn from './en.js';
import nextZh from './zh.js';
const i18n = new VueI18n({
    locale: localStorage.getItem('languageStorage') || 'en',
    messages: {
        'zh': {
            ...zhcnLocale,
            message: {
                ...nextZh
            }
        },
        'en': {
            ...enLocale,
            message: {
                ...nextEn
            }
        },
    },
});

export default i18n;