<template>
    <div class="box flex" v-if="dialogTableVisible">
        <el-dialog :title="dialogTitle ? dialogTitle : $t('message.edit')" :append-to-body="true" :width="width"
            :before-close="gbDialog" :visible.sync="dialogTableVisible" center>
            <!-- 编辑 -->
            <div class="dialog" v-if="bgShow">
                <div class="dialog_left">
                    <div class="dialog_left_groupBg">
                        {{ $t('message.fencename') }}:
                    </div>
                    <div class="dialog_left_groupBg">
                        {{ $t('message.Triggermode') }}:
                    </div>
                    <div class="dialog_left_groupBg" style="height: 410px;line-height: 410px;">
                        {{ $t('message.selectUser') }}:
                    </div>
                    <div class="dialog_left_groupBg" style="height: 150px;line-height: 170px;">
                        {{ $t('message.timesetting') }}:
                    </div>
                </div>
                <div class="dialog_right">
                    <div class="dialog_group">
                        <div class="dialog_group_top flex block">
                            <div class="dialog_group_input">
                                <el-input maxlength="15" v-model="fenceName" @input="bindInput"
                                    :placeholder="$t('message.Pleaseenterthefencename')">
                                </el-input>
                            </div>
                            <!-- <div class="dialog_group_red">*由1-15位中文,数字,字母，下划线组成，且不能以下划线开头！</div> -->
                            <div class="dialog_group_red" v-if="isText">(<span style="color: red;padding: 0px 5px;">*{{
        $t('message.tipsItconsists') }}</span>)</div>
                        </div>
                        <div class="dialog_group_top flex" style="color:#fff;">
                            <el-radio v-model="prohibit" label="1">{{ $t('message.Noentry') }}</el-radio>
                            <el-radio v-model="prohibit" label="0">{{ $t('message.Forbidden') }}</el-radio>
                        </div>
                        <!-- 开始 -->
                        <div class="dialog_group_user flex_between">
                            <div class="dialog_group_user_xz block" style="padding:0px 10px 10px 10px;height: 410px;">
                                <div class="dialog_group_user_xz_box" style="margin-right:25px;">
                                    <div class="dialog_group_user_xz_box_title">{{ $t('message.Usersonthefence') }}</div>
                                    <div class="dialog_group_user_search">
                                        <!-- 搜索 -->
                                        <div class="dialog_group_user_search_tree" style="height: calc(100% - 15px);">
                                            <div class="enclosureUser mouse"
                                                v-for="(item, index) in yesEnclosureUserArray" :key="item.User_ID">
                                                <img @click="deleteUser(index)" style="margin-right: 5px;"
                                                    src="../../assets/group/gb.png" alt="">
                                                {{ item.User_Name }} ({{ item.User_Account }})
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div style="width:40px;" class="dialog_group_user_but">
                                    <img src="../../assets/group/wholemove.png" alt="">
                                    <img src="../../assets/group/moveuser.png" alt="">
                                    <img src="../../assets/group/adduser.png" alt="">
                                    <img src="../../assets/group/wholeadd.png" alt="">
                                </div> -->
                                <div class="dialog_group_user_xz_box">
                                    <div class="dialog_group_user_xz_box_title">{{ $t('message.Notinfenceusers') }}
                                    </div>
                                    <div class="dialog_group_user_search">
                                        <!-- 搜索 -->
                                        <div class="search search_one flex">
                                            <div class="search_input flex">
                                                <!-- <el-input v-model="search" placeholder="群组/用户"></el-input> -->
                                                <!-- reserve-keyword -->
                                                <!-- 群组/用户/IMEI -->
                                                <el-input v-model="noEnclosureUserName"
                                                    :placeholder="$t('message.userImei')">
                                                </el-input>
                                                <img class="mouse" src="../../assets/group/gb.png" @click="bindEmpty()"
                                                    alt="" />
                                            </div>
                                            <div class="search_but mouse" @click="bindSearch()">
                                                <img src="../../assets/group/search.png" alt="" />
                                            </div>
                                        </div>
                                        <div class="dialog_group_user_search_tree">
                                            <!-- :render-content="renderContent" -->
                                            <el-tree @check="checkTow" ref="tree" :data="noEnclosureUserArray"
                                                show-checkbox node-key="User_ID" default-expand-all
                                                :expand-on-click-node="false">
                                                <div class="custom-tree-node ellipsis_app" slot-scope="{data}">
                                                    <span>{{ data.User_Name }} ({{ data.User_Account }})</span>
                                                </div>
                                            </el-tree>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 结束 -->
                    </div>
                    <!-- 时间设定 -->
                    <div class="dialog_group">
                        <div class="dialog_group_user flex_direction block" style="min-height:130px;margin-top: 20px;">
                            <div class="flex">
                                <div class="dialog_block dialog_text">
                                    <span class="demonstration">{{ $t('message.Validdate') }}</span>
                                    <el-date-picker prefix-icon="none" v-model="effectiveDate" type="daterange"
                                        :range-separator="$t('message.to')" :picker-options="pickerOptions"
                                        :start-placeholder="$t('message.startdate')"
                                        :end-placeholder="$t('message.enddate')">
                                    </el-date-picker>
                                </div>
                                <div class="dialog_block dialog_text">
                                    <span class="demonstration">{{ $t('message.Validperiod') }}</span>
                                    <el-time-picker is-range :clearable="false" prefix-icon="none" v-model="validPeriod"
                                        :range-separator="$t('message.to')" start-placeholder="00:00:00"
                                        end-placeholder="23:59:59" :placeholder="$t('message.Selecttimerange')">
                                    </el-time-picker>
                                </div>
                            </div>
                            <div class="dialog_block dialog_text">
                                <el-checkbox-group v-model="dayOfTheWeek">
                                    <el-checkbox style="color: #fff;" fill="var(--main-color)" text-color="#ffffff"
                                        v-for="city in weekly" :label="city.id" :key="city.id">{{ city.name }}
                                    </el-checkbox>
                                </el-checkbox-group>
                            </div>
                        </div>
                    </div>
                    <!-- 时间设定结束 -->
                </div>
            </div>
            <!-- 编辑结束 -->
            <div class="bottom">
                <el-button class="bc" @click="getSendOut()">{{ efId > 0 ? $t('message.Save') : $t('message.Create') }}
                </el-button>
                <el-button class="gb" @click="gbDialog()">{{$t('message.Cancel')}}</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import regfunc from "@/method/form/regfunc.js";
import axios from "axios";
import beasconfig from '@/api/beasconfig.js';
import EventBus from "@/eventBus/eventBus.js";
import { queryUserByEnclosure, queryEnclosureMember, queryUNEnclosureMember } from "@/administration/electronicsEnclosure.js";
// import { _debounce } from "@/method/utils.js";
const enclosureUrlapi = beasconfig.enclosureUrlapi;
export default {
    props: {
        // dialogTableVisible: {       //整个弹出框是否显示
        //     type: Boolean,
        //     default: true
        // },
        overlays: {       //对话框数据
            type: Object,
            default: () => ({})
        },
        dialogTitle: {
            type: String,
            default: ''
        },
        timeShow: {      //选择时间
            type: Boolean,
            default: false
        },
        width: {     //弹出框的宽
            type: String,
            default: '825px'
        },
        efId: {
            type: Number,
            default: 0
        },
        bgShow: {    //编辑显示
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            userInfo: {

            },
            pickerOptions: {
                //未来时间
                disabledDate(time) {
                    return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
                }
            },
            dialogTableVisible: true,
            groupName: '',        //群组名称
            children: [
                {
                    id: 1,
                    label: '用户用户用户用户用户用户用户用户用户用户用户用户'
                }
            ],
            paging: {
                pageNo: 1,
                pageSize: 10,
                msgType: 1
            },
            broadcastRecordList: [],
            yesEnclosureUserArray: [],
            yesEnclosureUserName: '',    //在组围栏用户名称
            noEnclosureUserArray: [],
            noEnclosureUserName: '',     //不在组围栏用户名称
            fenceName: '',//围栏名称
            validStartTime: 0,
            validEndTime: 0,
            effectiveTimes: '',
            weekly: [{
                id: 2,
                name: this.$t('message.Monday')
            },
            {
                id: 3,
                name: this.$t('message.Tuesday')
            },
            {
                id: 4,
                name: this.$t('message.Wednesday')
            },
            {
                id: 5,
                name: this.$t('message.Thursday')
            },
            {
                id: 6,
                name: this.$t('message.Friday')
            },
            {
                id: 7,
                name: this.$t('message.Saturday')
            },
            {
                id: 1,
                name: this.$t('message.Sunday')
            }],
            validPeriod: [new Date(2016, 9, 10), new Date(2016, 9, 10, 23, 59, 59)],//有效时段
            dayOfTheWeek: [2, 3, 4, 5, 6, 7, 1],
            effectiveDate: '',//有效日期
            prohibit: "1",      //禁止
            pointList: [],
            efRemark: '',     //1是自定义  2是区域
            isText: true
        };
    },
    created() {
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        if (this.efId > 0) {
            this.getFenceDetails();
            this.getQueryEnclosureMember();
            this.getQueryUNEnclosureMember();
        } else {
            this.getQueryUserByEnclosure();
        }
    },
    beforeCreate: function () { },
    methods: {
        getQueryEnclosureMember() {  //查询围栏成员
            let data = {
                enclosureId: this.efId
            }
            queryEnclosureMember(data).then(res => {
                // console.log(res)
                if (res.data.code == 200) {
                    this.yesEnclosureUserArray = res.data.data;
                } else {
                    this.$MessageWarning(res.data.msg);
                }
            })
        },
        getQueryUNEnclosureMember() {   //查询非围栏成员
            let data = {
                uId: this.userInfo.id,
                enclosureId: this.efId,
                keyword: this.noEnclosureUserName
            }
            queryUNEnclosureMember(data).then(res => {
                // console.log(res)
                if (res.data.code == 200) {
                    this.noEnclosureUserArray = res.data.data;
                } else {
                    this.$MessageWarning(res.data.msg);
                }
            })
        },
        /*围栏详情*/
        getFenceDetails() {
            axios.get(`${enclosureUrlapi}/electronicFence/${this.efId}`)
                // axios.get(`/liang/electronicFence/${this.efId}`)
                .then(res => {
                    // console.log(res)
                    // var that = this;
                    var effectiveDates = [];
                    var data = res.data.data;
                    this.fenceName = data.efName; //围栏名称
                    this.prohibit = JSON.stringify(data.efType); //禁出 禁入
                    this.$store.state.efRemark = data.efRemark; //是否显示
                    // this.ddyId = data.ddyId; //调度员id
                    this.pointList = data.pointList; //获取经纬度坐标
                    let [startTime, endTime] = data.effectiveTimes.split('|'); //时段
                    if (data.validStartTime != 0) {
                        this.validStartTime = this.$moment(data.validStartTime).format("YYYY-MM-DD"); //有效日期
                        this.validEndTime = this.$moment(data.validEndTime).format("YYYY-MM-DD"); //有效日期结束
                    } else {
                        this.validStartTime = '';
                        this.validEndTime = '';
                    }
                    const dateTest = this.$moment(new Date()).format('YYYY-MM-DD');
                    if (startTime == undefined || startTime == "" || endTime == undefined || endTime == "") {
                        this.validPeriod = '';
                    } else {
                        startTime = dateTest + ' ' + startTime;
                        endTime = dateTest + ' ' + endTime;
                        this.validPeriod = [new Date(startTime), new Date(endTime)];
                    }

                    this.dayOfTheWeek = data.periodData.split(","); //星期几
                    this.dayOfTheWeek = this.dayOfTheWeek.map(function (data) {
                        return +data;
                    });
                    effectiveDates[0] = this.validStartTime;
                    effectiveDates[1] = this.validEndTime;
                    this.effectiveDate = effectiveDates;
                })
        },
        getQueryUserByEnclosure() {   //查询非围栏成员
            let data = {
                oId: this.userInfo.User_CompanyID,
                keyword: this.noEnclosureUserName
            }
            queryUserByEnclosure(data).then(res => {
                if (res.data.code == 200) {
                    if (this.yesEnclosureUserArray.length > 0) {
                        let array = this.yesEnclosureUserArray.map(item => item.User_ID);
                        this.$refs.tree.setCheckedKeys(array);
                    }
                    this.noEnclosureUserArray = res.data.data;
                } else {
                    this.$MessageWarning(res.data.msg);
                }
            })
        },
        bindSearch() {      //搜索
            if (this.efId > 0) {
                this.getQueryUNEnclosureMember();
            } else {
                this.getQueryUserByEnclosure();
            }
        },
        bindEmpty() {        //清空输入框值
            this.noEnclosureUserName = "";
        },
        checkTow(data) {     //不在组选中
            let index = this.yesEnclosureUserArray.findIndex(item => data.User_ID == item.User_ID);
            if (index >= 0) {
                this.yesEnclosureUserArray.splice(index, 1);
            } else {
                this.yesEnclosureUserArray.push(data)
            }
            // this.yesEnclosureUserArray = tree.checkedNodes;
        },
        deleteUser(index) {       //删除用户
            this.yesEnclosureUserArray.splice(index, 1);
            let array = this.yesEnclosureUserArray.map(item => item.User_ID);
            this.$refs.tree.setCheckedKeys(array);
        },
        // 打开对话框
        openPg() {
            this.dialogTableVisible = true;
        },
        // 关闭对话框
        gbDialog() {
            this.dialogTableVisible = false;
            // this.$emit('clearOverwrites');
            EventBus.$emit("emptyMap", 1);
        },
        getSendOut() {       //发送
            let that = this;
            if (!regfunc.wAgentName.test(that.fenceName)) {
                that.$MessageWarning(that.$t('message.tipsItconsistsName'));
                return
            }
            if (that.prohibit == null) {
                that.$MessageWarning(that.$t('message.Pleaseselectamethodofentryandexit'));
                return
            }
            if (that.dayOfTheWeek.length <= 0) {
                that.$MessageWarning(that.$t('message.Pleaseselectatleastoneday'));
                return
            }
            let array = this.overlays.Ro;
            let pointList = '';
            let periodType = 0;
            if (array) {
                // this.coordinateCalculationArea(array);
                that.pointList = '';
                for (var i = 0; i < array.length; i++) {
                    var lat = array[i].lat;
                    var lng = array[i].lng;
                    if (pointList == null || pointList == "") {
                        pointList = lng + "," + lat;
                    } else {
                        pointList = pointList + "|" + lng + "," + lat;
                    }
                }
                /*经纬度*/
                that.pointList = pointList;
            } else {
                that.pointList = this.overlays.list;
            }
            let timezoneOffset = -(new Date().getTimezoneOffset()); //时区
            let uids = that.yesEnclosureUserArray.map(item => item.User_ID).join(',');
            if (that.effectiveDate) {
                that.validStartTime = that.$moment(that.effectiveDate[0]).valueOf();
                that.validEndTime = that.$moment(that.effectiveDate[1]).valueOf();
            } else {
                that.validStartTime = 0
                that.validEndTime = 0
            }
            var effectiveTimes1, effectiveTimes2
            if (that.validPeriod) {
                effectiveTimes1 = that.$moment(that.validPeriod[0]).format("HH:mm:ss");
                effectiveTimes2 = that.$moment(that.validPeriod[1]).format("HH:mm:ss");
                that.effectiveTimes = effectiveTimes1 + '|' + effectiveTimes2;
            } else {
                effectiveTimes1 = '';
                effectiveTimes2 = '';
            }
            if (that.dayOfTheWeek.length > 0) {
                periodType = 1
            } else {
                periodType = 0
            }
            let parmas = {
                companyId: parseInt(that.userInfo.User_CompanyID),
                groupId: 0,
                // efId: parseInt(this.efId),//围栏id
                efRemark: that.$store.state.efRemark,
                efName: that.fenceName,
                pointType: 0,//地图类型
                validStartTime: that.$moment(that.validStartTime).valueOf(),//开始日期
                validEndTime: that.$moment(that.validEndTime).valueOf(), // 结束日期
                effectiveTimes: that.effectiveTimes, //时段
                periodData: that.dayOfTheWeek.join(','), //选中了周几
                efType: that.prohibit,  //禁入禁出
                periodType: periodType, //是否有周期
                uids: uids,// 用户id
                ddyId: parseInt(that.userInfo.User_ID),// 调度员id
                timeOffset: parseInt(timezoneOffset),//时区
                pointList: that.pointList//经纬度数据
            };
            // console.log(parmas)
            if (that.efId > 0) {
                parmas.efId = parseInt(that.efId) //围栏id
            }
            axios.post(`${enclosureUrlapi}/electronicFence/saveTwo`, parmas)
                .then(res => {
                    if (res.data.code == 200) {
                        that.dialogTableVisible = false;
                        if (that.efId <= 0) {
                            that.$store.state.toUpdateEnclosure = 1;
                            that.$MessageSuccess(that.$t('message.Createdsuccessfully'));
                        } else {
                            that.$store.state.toUpdateEnclosure = 2;
                            that.$MessageSuccess(that.$t('message.message.update_successfully'));
                        }
                        EventBus.$emit("emptyMap", 1);
                        // that.$emit('clearOverwrites')
                    } else if (res.data.code == 511) {
                        that.$MessageWarning(that.$t('message.Thefencenameisduplicated'));
                    } else {
                        that.$MessageWarning(res.data.message);
                    }
                })
        },
        bindInput(value) {        //不能输入
            let reg = regfunc.wAgentName;
            let n = value.search(reg);
            if (n === -1 && value.length > 1) {
                this.fenceName = value.slice(0, n);
            } else if (n === -1 && value.length >= 1) {
                this.fenceName = value.slice(0, n);
            }
        },
        deweight(arr) {      //数组去重
            let de_arr = []
            arr.forEach(i => {
                let isTrue = de_arr.every((j) => j.id != i.id);
                isTrue ? de_arr.push(i) : ''
            });
            return de_arr;
        }
    },
    mounted() {

    },
    filters: {
        rounding: function (duration) { //录音取整
            return Math.round(duration)
        },
        gbTime: function (data) {

            return Math.round(data / 1000);
        }
    },
    watch: {
        fenceName(value) {
            if (value && value != '') {
                this.isText = false;
            } else {
                this.isText = true;
            }
        }
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
::-webkit-scrollbar {
    width: 6px;
    height: 100%;
    background-color: #ccc;
}

::-webkit-scrollbar-track {
    width: 6px;
    background-color: #4D4D4D;
}

::-webkit-scrollbar-thumb {
    background-color: #727171;
}

.box {
    width: 100vw;
    height: 100vh;
    color: #fff;
}

/deep/.el-dialog {
    background: #4D4D4D;
    margin-top: 20vh;

    &__title {
        color: #fff;
        font-size: 16px;
    }

    .el-dialog__headerbtn .el-dialog__close {
        color: var(--main-color) !important;
    }

    .dialog {
        width: 100%;
        // background-color: #383838;
        margin: auto;
        color: #fff;
        // border: 1px solid #6e6d6d;
        display: flex;

        &_block {
            flex: 1;
            text-align: center;

            .el-input--prefix .el-input__inner {
                padding-left: 15px;
            }

            .el-range-separator {
                color: #fff;
            }

            .el-date-editor .el-range-input {
                color: #fff;
                background-color: transparent;
            }

            .el-input__inner {
                width: 330px;
                height: 35px;
                background-color: transparent;
                border: 1px solid #666666;
                color: #fff;
                margin-top: 10px;
            }

            /deep/.el-checkbox__inner::after {
                border: 1px solid #000000;
            }
        }

        &_text {
            font-size: 14px;
            height: 65px;
            padding: 10px 0px;

        }

        &_left {
            width: 100px;
            height: 100%;
            background-color: #4D4D4D;

            &_groupBg {
                width: 100%;
                height: 45px;
                line-height: 45px;
                text-align: center;
            }

            &_bg {
                width: 100%;
                height: 120px;
                line-height: 120px;
                text-align: center;
            }

            &_bgOne {
                background-color: #383838;
            }
        }

        &_right {
            flex: 1;
            height: 100%;
            // margin: auto;
            margin-right: 20px;

            &_top {
                width: 90%;
                margin: 15px auto 25px auto;
                height: 340px;
                overflow: auto;
                border: 1px solid #6e6d6d;

                &_dh {
                    display: flex;
                    align-items: center;
                    position: relative;
                    width: 97%;
                    margin: 15px auto;

                    &_left {
                        border-style: solid;
                        border-color: transparent;
                        border-width: 6px 8px 6px 0px;
                        border-right-color: #2e7777;
                    }

                    &_right {
                        // flex: 1;
                        width: 80%;
                        min-height: 75px;
                        background-color: #2e7777;
                        border-radius: 10px;
                        font-size: 14px;

                        &_text {
                            padding: 15px 10px;
                            line-height: 25px;
                        }
                    }

                    &_rightOne {
                        width: 149px;
                        min-height: 55px;
                        background-color: #2e7777;
                        border-radius: 10px;
                        font-size: 14px;

                        img {
                            width: 15px;
                            height: 21px;
                            margin-left: 15px;
                        }

                        span {
                            margin-left: 10px;
                        }
                    }

                    &_text {
                        // position: absolute;
                        // left: calc(80% + 16px);
                        // bottom: 0px;
                        color: #727171;
                        margin-top: 35px;
                        margin-left: 10px;
                    }
                }
            }

            &_bottom {
                width: 90%;
                margin: auto;
                height: 85px;
                border: 1px solid #6e6d6d;

                .el-textarea__inner {
                    // width: 100%;
                    padding: 15px !important;
                    // height: 85px;
                    background-color: transparent;
                    color: #fff;
                    border: none;
                    font-size: 14px;
                    resize: none;
                }
            }

            &_ly {
                width: 90%;
                margin: auto;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &_img {
                width: 302px;
                height: 66px;
                // margin: auto;
                background: url("../../assets/gb/lyaj.png");
                background-size: 100% 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &_delet {
                width: 88px;
                height: 35px;
                line-height: 35px;
                text-align: center;
                background-color: #2e7777;
                opacity: 0.9;
                border: 1px solid #30aeae;
                color: var(--main-color);
                margin-left: 35px;
            }
        }

        // 群组编辑
        &_group {
            width: 100%;
            margin: auto;

            &_top {
                width: 100%;
                height: 45px;
                // border-bottom: 1px solid #6e6d6d;

                .el-input__inner {
                    width: 100%;
                    height: 40px;
                    background-color: transparent;
                    border: none;
                    color: #fff;
                    border-radius: 0px !important;
                }
            }

            &_red {
                // font-size: 12px;
                // color: red;
                font-size: 12px;
                // position: absolute;
                color: #cccccc;
                // left: 210px;
            }

            &_input {
                width: 100%;
                height: 40px;
                // margin: 0px 10px;
            }

            &_user {
                width: 100%;
                height: 100%;

                // height: 370px;
                &_text {
                    margin-right: 15px;
                }

                &_xz {
                    // flex: 1;
                    width: 100%;
                    display: flex;

                    &_box {
                        // flex: 1;
                        width: 50%;
                        // width: 280px;
                        height: 370px;

                        &_title {
                            width: 100%;
                            height: 35px;
                            line-height: 35px;
                            text-align: center;
                            color: #fff;
                        }
                    }
                }

                &_but {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;

                    img {
                        width: 23px;
                        height: 19px;
                        margin-top: 25px;
                    }
                }

                &_search {
                    width: 100%;
                    height: 370px;
                    border: 1px solid #6e6d6d;
                    overflow: hidden;

                    &_tree {
                        width: 100%;
                        height: calc(100% - 45px);
                        overflow: auto;
                        margin-top: 10px;
                    }
                }

                &_gl {
                    flex: 1;
                    display: flex;
                    margin-top: 20px;

                    &_box {
                        width: 215px;
                        text-align: center;

                        &_title {
                            width: 100%;
                            height: 35px;
                            line-height: 35px;
                            text-align: center;
                            color: var(--main-color);
                        }

                        &_content {
                            width: 100%;
                            height: 22px;
                            line-height: 22px;
                        }

                        &_contentOne {
                            border: 1px solid #6e6d6d;
                            border-radius: 2px;
                        }
                    }
                }
            }

            &_delet {
                width: 108px;
                height: 35px;
                line-height: 35px;
                text-align: center;
                background-color: #2e7777;
                opacity: 0.9;
                border: 1px solid #30aeae;
                color: var(--main-color);
                margin-left: 10px;
            }
        }
    }

}

.dialog_group_user_gl_box_content /deep/.el-input__inner {
    height: 22px !important;
    line-height: 22px !important;
    background-color: transparent;
    border: none;
    color: #fff;
    text-align: center;
}

.dialog_group_user_gl_box_content /deep/.el-select .el-input .el-select__caret {
    color: var(--main-color);
}

.dialog_group_user_gl_box_content /deep/.el-input__icon {
    line-height: 25px;
}

.search_one {
    /deep/.el-input__inner {
        width: 100%;
        // width: 100%;
        height: 28px;
        line-height: 28px;
        background-color: #333333;
        color: #ffffff;
        border: none;
        border-radius: 0px;
        padding: 0px 10px;
    }
}

.search_tow {
    width: 415px;
    height: 45px;
    border: 1px solid #2e7777;
    background-color: #383838 !important;
    border-radius: 2px;

    /deep/.el-input__inner {
        width: 100%;
        // width: 100%;
        height: 45px !important;
        line-height: 45px !important;
        background-color: #383838 !important;
        color: #ffffff;
        border: none !important;
        border-radius: 0px;
        padding: 0px 10px;
    }

    &_input {
        flex: 1;
        height: 100%;
        background-color: #383838;
        color: #ffffff !important;

        img {
            width: 12px;
            height: 12px;
            margin: 0px 15px;
        }
    }

    &_but {
        width: 55px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        background-color: var(--main-color);
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 17px;
            height: 17px;
        }
    }
}

.search {
    width: 100%;
    height: 28px;
    background-color: var(--main-color);
    border: 1px solid var(--main-color);

    &_input {
        flex: 1;
        height: 100%;
        background-color: #333333;
        color: #ffffff !important;


        img {
            width: 12px;
            height: 12px;
            margin: 0px 15px;
        }
    }

    &_but {
        width: 40px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        background-color: var(--main-color);
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 17px;
            height: 17px;
        }
    }
}


.bottom {
    width: 100%;
    text-align: center;
    padding: 15px 0px;

    /deep/.gb.el-button {
        width: 89px;
        height: 38px;
        border: 1px solid #fff !important;
        background-color: transparent !important;
        color: #fff !important;
    }

    /deep/.bc.el-button {
        width: 89px;
        height: 38px;
        border: 1px solid var(--main-color) !important;
        background-color: transparent !important;
        color: var(--main-color) !important;
        margin-right: 30px;
    }
}


/deep/.el-tree {
    background-color: transparent;
    color: #fff;

    .el-tree-node__content:hover,
    .el-upload-list__item:hover {
        background-color: transparent !important;
    }

    .el-tree-node:focus,
    .is-expanded:focus,
    .is-focusable:focus {
        background-color: transparent !important;
    }

    .el-tree-node.is-current>.el-tree-node__content {
        background: transparent !important;
    }

    .el-tree-node__expand-icon.expanded {
        color: var(--main-color);
    }

    .el-checkbox__inner {
        background-color: #343434 !important;
    }

    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner,
    .el-checkbox__inner:hover {
        border-color: #dcdfe6;
    }

    .el-checkbox__inner::after {
        border-color: var(--main-color);
    }

    .el-tree-node:focus>.el-tree-node__content {
        background: transparent !important;
    }

    .el-tree-node__content>.el-tree-node__expand-icon {
        padding: 0px;
    }

    .el-tree-node__content {
        line-height: 28px;
    }

    .el-tree-node__label {
        width: 230px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

/deep/.el-dialog--center .el-dialog__body {
    padding: 0px !important;
}

.el-checkbox__inner::after {
    color: #000;
}

/deep/.el-radio {
    color: #fff;

    .el-radio__input.is-checked .el-radio__inner {
        border-color: #ffffff;
        background: #000000;
    }

    .el-radio__input.is-checked+.el-radio__label {
        color: #fff;
    }

    .el-radio__inner::after {
        width: 8px;
        height: 8px;
        background-color: var(--main-color);
    }
}

/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: var(--main-color);
    border-color: #ffffff;
}

/deep/.el-checkbox__input.is-checked+.el-checkbox__label {
    color: #fff;
}

.block {
    background-color: #383838;
}

.enclosureUser {
    width: 94%;
    height: 28px;
    line-height: 28px;
    margin: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.flex {
    display: flex;
    align-items: center;
}

.flex_between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex_direction {
    //垂直居中
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: center;
}

.custom-tree-node {
    width: calc(100% - 50px);
}
</style>