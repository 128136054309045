<template>
    <el-dialog :title="$t('message.edit')" :append-to-body="true" :before-close="gbDialog" width="962px"
        :visible.sync="dialogTableVisible" center>
        <!-- 编辑 -->
        <div class="dialog flex_between" style="height: 505px;">
            <div class="dialog_left mouse">
                <div class="dialog_left_groupBg prohibitCopy" :class="groupId == item.id ? 'dialog_left_bgOne' : ''"
                    v-for="item in groupArray" :key="item.id" @click="bindSwitchGb(item.id)">
                    {{ item.name }}
                </div>
            </div>
            <div class="dialog_right">
                <div class="dialog_group" v-if="groupId == 1">
                    <div class="dialog_group_top flex">
                        <div>{{ $t('message.groupName') }}：</div>
                        <div class="dialog_group_input">
                            <el-input v-model.trim="groupName" @focus="focus" @blur="blur" @input="bindInput" maxlength="15"
                                :placeholder="$t('message.Pleaseenterthegroupname')">
                            </el-input>
                        </div>
                        <div class="dialog_group_red">*{{ $t('message.pleaseenteragroupnameTips') }}</div>
                    </div>
                    <!-- 开始 -->
                    <div class="dialog_group_user flex_between">
                        <div class="dialog_group_user_text">
                            <div>
                                {{ $t('message.selectUser') }} :
                            </div>
                            <div class="dialog_group_red" style="margin-top: 10px;">
                                *{{ $t('message.Editstakeeffectimmediately') }}
                            </div>
                        </div>
                        <div class="dialog_group_user_xz">
                            <div class="dialog_group_user_xz_box">
                                <div class="dialog_group_user_xz_box_title">{{ $t('message.usersingroup') }}</div>
                                <div class="dialog_group_user_search">
                                    <!-- 搜索 -->
                                    <div class="search search_one flex">
                                        <div class="search_input flex">
                                            <!-- <el-input v-model="search" placeholder="群组/用户"></el-input> -->
                                            <!-- reserve-keyword -->
                                            <el-input v-model="InGroupUser.searchName" @focus="focus" @blur="blur"
                                                :placeholder="$t('message.UsernameImei')">
                                            </el-input>
                                            <img @click="bindEmpty(1)" class="mouse" src="@/assets/group/gb.png" alt="" />
                                        </div>
                                        <div class="search_but mouse" @click="bindSearch(1)">
                                            <img src="@/assets/group/search.png" alt="" />
                                        </div>
                                    </div>
                                    <div class="dialog_group_user_search_tree">
                                        <el-tree @check="checkOne" :data="InGroupUser.userArray" show-checkbox node-key="id"
                                            default-expand-all :expand-on-click-node="false">
                                        </el-tree>
                                    </div>
                                </div>
                            </div>
                            <div style="width:40px;" class="dialog_group_user_but">
                                <el-tooltip :content="$t('message.Removeall')" placement="top">
                                    <img class="mouse" src="@/assets/group/wholemove.png" @click="changeGroup(1)" alt="">
                                </el-tooltip>
                                <el-tooltip :content="$t('message.removeselectedusers')" placement="top">
                                    <img class="mouse" src="@/assets/group/moveuser.png" @click="changeGroup(2)" alt="">
                                </el-tooltip>
                                <el-tooltip :content="$t('message.Addtheselecteduser')" placement="top">
                                    <img class="mouse" src="@/assets/group/adduser.png" @click="changeGroup(3)" alt="">
                                </el-tooltip>
                                <el-tooltip :content="$t('message.Addall')" placement="top">
                                    <img class="mouse" src="@/assets/group/wholeadd.png" @click="changeGroup(4)" alt="">
                                </el-tooltip>
                            </div>
                            <div class="dialog_group_user_xz_box">
                                <div class="dialog_group_user_xz_box_title">{{ $t('message.Usernotingroup') }}</div>
                                <div class="dialog_group_user_search">
                                    <!-- 搜索 -->
                                    <div class="search search_one flex">
                                        <div class="search_input flex">
                                            <!-- <el-input v-model="search" placeholder="群组/用户"></el-input> -->
                                            <!-- reserve-keyword -->
                                            <el-input v-model="noGroupUser.searchName" @focus="focus" @blur="blur"
                                                :placeholder="$t('message.UsernameImei')">
                                            </el-input>
                                            <img @click="bindEmpty(2)" class="mouse" src="@/assets/group/gb.png" alt="" />
                                        </div>
                                        <div class="search_but mouse" @click="bindSearch(2)">
                                            <img src="@/assets/group/search.png" alt="" />
                                        </div>
                                    </div>
                                    <div class="dialog_group_user_search_tree">
                                        <el-tree :data="noGroupUser.userArray" @check="checkTow" show-checkbox node-key="id"
                                            default-expand-all :expand-on-click-node="false">
                                        </el-tree>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 结束 -->
                </div>
                <div class="dialog_group" v-if="groupId == 4">
                    <div class="dialog_group_top flex">
                        <div style="width:415px;">
                            <!-- 搜索 -->
                            <div class="search_tow flex">
                                <div class="search_tow_input flex">
                                    <el-input v-model="InGroupUser.searchName" @focus="focus" @blur="blur"
                                        :placeholder="$t('message.UsernameImei')"></el-input>
                                    <img @click="bindEmpty(1)" class="mouse" src="@/assets/group/gb.png" alt="" />
                                </div>
                                <div class="search_tow_but mouse" @click="bindSearch(1)">
                                    <img src="@/assets/group/search.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 开始 -->
                    <div class="dialog_group_user flex_between" style="width:100%;height: 400px;margin-top: 20px;">
                        <div class="dialog_group_user_xz">
                            <div class="dialog_group_user_xz_box" style="width:100%;height: 400px;">
                                <div class="dialog_group_user_search" style="border:none;">
                                    <div class="dialog_group_user_search_tree">
                                        <el-tree ref="groupTree" @check="handleCheck" :data="InGroupUser.userArray"
                                            show-checkbox node-key="id" default-expand-all :expand-on-click-node="false">
                                        </el-tree>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 结束 -->
                </div>
                <!-- 开始 -->
                <div style="width:100%;height:100%;overflow: auto;" v-if="groupId == 2">
                    <div class="dialog_group">
                        <div class="dialog_group_top flex">
                            <div style="width:415px;">
                                <!-- 搜索 -->
                                <div class="search_tow flex">
                                    <div class="search_tow_input flex">
                                        <el-input v-model="InGroupUser.searchName" @focus="focus" @blur="blur"
                                            :placeholder="$t('message.UsernameImei')"></el-input>
                                        <img @click="bindEmpty(1)" class="mouse" src="@/assets/group/gb.png" alt="" />
                                    </div>
                                    <div class="search_tow_but mouse" @click="bindSearch(1)">
                                        <img src="@/assets/group/search.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dialog_group_user_gl">
                            <div class="dialog_group_user_gl_box" style="flex: 1;">
                                <div class="dialog_group_user_gl_box_title">{{ $t('message.userName') }}</div>
                                <div style="margin-bottom:10px;border: 1px solid #383838;"
                                    class="dialog_group_user_gl_box_content ellipsis_app"
                                    v-for="item in InGroupUser.userArray" :key="item.id">
                                    {{ item.label }}
                                </div>
                            </div>
                            <div class="dialog_group_user_gl_box">
                                <div class="dialog_group_user_gl_box_title">{{ $t('message.Userpriority') }}</div>
                                <div style="margin-bottom:10px;"
                                    class="dialog_group_user_gl_box_content dialog_group_user_gl_box_contentOne"
                                    v-for="item in InGroupUser.userArray" :key="item.id">
                                    <el-select v-model="item.priorities" :placeholder="$t('message.Pleasechoose')"
                                        @change="changeUserAdministration" @click.native="prioritiesbtn(item.User_ID)">
                                        <el-option v-for="item in options" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                            <div class="dialog_group_user_gl_box">
                                <div class="dialog_group_user_gl_box_title" style="color:red;">*{{
                                    $t('message.Editstakeeffectimmediately') }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 结束 -->
                <!-- 开始 -->
                <div class="dialog_group" v-if="groupId == 3 && false">
                    <div class="dialog_group_top flex">
                        <div>{{ $t('message.Host') }} ：</div>
                        <div class="dialog_group_input dialog_group_zcr">
                            <el-input :disabled="true" @focus="focus" @blur="blur" v-model="groupHost"
                                :placeholder="$t('message.Pleasechoose')">
                            </el-input>
                        </div>
                        <div class="dialog_group_delet mouse" @click="deleteHost">{{ $t('message.Deletemoderator') }}</div>
                    </div>
                    <div class="dialog_group_user" style="margin-top:20px;display:flex;">
                        <div class="dialog_group_user_text" style="margin-top:80px;">
                            <div>
                                {{ $t('message.SelectHost') }}
                            </div>
                        </div>
                        <div class="dialog_group_user_xz" style="flex:none;">
                            <div class="dialog_group_user_xz_box">
                                <div class="dialog_group_user_search">
                                    <!-- 搜索 -->
                                    <div class="search search_one flex ">
                                        <div class="search_input flex">
                                            <!-- !-- el-input v-model=search placeholder=群组用户el-input --
                                                !-- reserve-keyword -- -->
                                            <el-input @focus="focus" @blur="blur" v-model="InGroupUser.searchName"
                                                :placeholder="$t('message.UsernameImei')">

                                            </el-input>
                                            <img @click="bindEmpty(1)" class="mouse" src="@/assets/group/gb.png" alt= />
                                        </div>
                                        <div class="search_but mouse" @click="bindSearch(1)">
                                            <img src="@/assets/group/search.png" alt= />
                                        </div>
                                    </div>
                                    <div class="dialog_group_user_search_tree">
                                        <!-- <el-tree ref="host" :data="InGroupUser.userArray" @check="handleNodeClick"
                                            show-checkbox node-key="User_ID">
                                        </el-tree> -->
                                        <el-radio-group v-model="hostId" @change="handleNodeClick">
                                            <el-radio v-for="item in InGroupUser.userArray" :key="item.User_ID"
                                                :label="item.User_ID">
                                                {{ item.label }}
                                            </el-radio>
                                        </el-radio-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dialog_group_red" style="margin-top:80px;margin-left:15px;">
                            {{ $t('message.Setuphost') }}
                        </div>
                    </div>
                </div>
                <!-- 结束 -->
            </div>
        </div>
        <!-- 编辑结束 -->
        <div class="bottom">
            <el-button class="bc" @click="getSendOut($event)" @keyup.prevent @keydown.enter.prevent>{{ $t('message.Save') }}
            </el-button>
            <el-button class="gb" @click="gbDialog()">{{ $t('message.Cancel') }}</el-button>
        </div>
    </el-dialog>
    <!-- </div> -->
</template>

<script>
import { updateGroup, queryMember, queryUnMember, removeMember, addMember, updatePriorities, updaetHost, queryGroup } from "@/administration/dispatchLntercom.js";
import regfunc from "@/method/form/regfunc.js";
import global from "@/method/globalMethod.js";
import axios from "axios";
import qs from "qs";
import beasconfig from '@/api/beasconfig.js';
const selfUrlapi = beasconfig.selfUrlapi;
export default {
    props: {
        dialogTitle: {
            type: String,
            default: ''
        },
        width: {     //弹出框的宽
            type: String,
            default: '30%'
        },
        butName: {       //按钮名称
            type: String,
            default: '发送'
        },
        cancelBut: {       //取消按钮
            type: String,
            default: '取消'
        },
        dialogData: {       //对话框数据
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            userInfo: {},
            dialogTableVisible: true,
            groupArray: [
                {
                    id: 1,
                    name: this.$t('message.groupEdit')
                },
                {
                    id: 4,
                    name: this.$t('message.Grouptransfer')
                },
                {
                    id: 2,
                    name: this.$t('message.UserManagement')
                },
                // {
                //     id: 3,
                //     name: this.$t('message.Setmoderator')
                // }
            ],
            groupId: 1,
            groupName: '',        //群组名称
            groupHost: '',   //支持人
            options: [      //用户优先级
                {
                    value: 1,
                    label: this.$t('message.levelone')
                }, {
                    value: 2,
                    label: this.$t('message.Secondary')
                }, {
                    value: 3,
                    label: this.$t('message.Levelthree')
                }, {
                    value: 4,
                    label: this.$t('message.Levelfour')
                }, {
                    value: 5,
                    label: this.$t('message.Fifthgrade')
                }
            ],
            userValue: '',  //用户优先级
            InGroupUser: {       //在组用户
                searchName: '',  //搜索用户名称
                userArray: [],
                selectUser: []
            },
            noGroupUser: {       //不在组用户
                searchName: '',  //搜索用户名称
                userArray: [],
                selectUser: []
            },
            hostId: 0,       //主持人id
            usertransfer: 0,  //群组转让用户ID
            userQuanxian: true
        };
    },
    created() {
        if (this.dialogData.mmcount <= 0) {
            this.groupArray.splice(1, 1);
        } else {
            let bool = this.$store.state.userInfo.User_ID == this.dialogData.mmcount;
            this.userQuanxian = bool;
            if (!bool) {
                this.groupId = 2;
                this.groupArray = [{
                    id: 2,
                    name: this.$t('message.UserManagement')
                }];
            }
        }
    },
    beforeCreate: function () { },
    methods: {
        bindInput(e) {        //不能输入
            let num = 0;
            if (e) {
                num = global.getSpaceLength(e);
            }
            let m = e.length - num;
            if (m > 15) {
                this.groupName = this.inputLimit.name;
                return
            }
            this.groupName = e.replace(regfunc.trimTow, ' ');
            this.groupName = this.groupName.substring(0, 15 + num);
            // this.groupName = global.limitInput(e);
        },
        // 切换
        bindSwitchGb(id) {
            if (this.groupId == id) {

                return
            }
            this.InGroupUser.userArray = [];
            this.groupId = id;
            this.InGroupUser.searchName = "";
            this.noGroupUser.searchName = "";
            this.getQueryMember();
            this.getQueryUnMember();
            this.setCheckedKeys();
        },
        handleCheck(nodes, resolve) {
            //nodes节点所对应的对象；resolve选中状态对象
            if (resolve.checkedKeys.length > 0) {
                this.usertransfer = nodes.id;
                this.setCheckedKeys();
            } else {
                this.usertransfer = 0;
            }
        },
        setCheckedKeys() {
            this.$nextTick(() => {
                if (this.$refs.groupTree) {
                    this.$refs.groupTree.setCheckedKeys([this.usertransfer]);
                }
            })
        },
        checkOne(data, tree) {       //在组选中
            this.InGroupUser.selectUser = tree.checkedNodes;
        },
        checkTow(data, tree) {     //不在组选中
            this.noGroupUser.selectUser = tree.checkedNodes;
        },
        // 关闭对话框
        gbDialog() {
            this.$emit('closeEditqz');
        },
        getSendOut(event) {       //发送
            // console.log(event.pointerType)
            if (event.pointerType == '') {    //按空格的时候是空

                return
            }
            if (this.userQuanxian) {
                this.groupEdit();
                if (this.dialogData.mmcount > 0) {
                    this.updateOwner();
                }
            } else {
                this.$emit('successEditqz');
            }
        },
        prioritiesbtn(id) {        //
            // console.log(id)
            this.userValue = id;
        },
        changeUserAdministration(e) {       //用户管理修改优先级
            let that = this;
            let dialogData = that.dialogData;
            let data = {
                uId: that.userInfo.id,
                gId: dialogData.Gid,
                uid: that.userValue,
                priorities: e
            }
            updatePriorities(data).then(res => {
                console.log(res)
                if (res.data.code == 200) {
                    that.$message({
                        message: that.$t('message.message.Prioritysetsuccessfully'),
                        type: "success",
                        center: true,
                        offset: 450
                    });
                } else {
                    that.$MessageWarning(res.data.message);
                }
            })
        },
        updateOwner() {
            let find = this.InGroupUser.userArray.find(item => item.id == this.usertransfer);
            if (find) {
                axios.post(`${selfUrlapi}/group/updateOwner`, qs.stringify({
                    creator: this.$store.state.userInfo.User_ID,
                    cgId: this.dialogData.id,
                    newCreator: find.User_ID
                }),
                    {
                        headers: beasconfig.tokenSl
                    })
                    .then(res => {
                        if (res.data.code == 0) {
                            this.$emit('successEditqz');
                        } else {
                            this.$MessageWarning(res.data.msg);
                        }
                    })
            }
        },
        groupEdit() {       //群组编辑
            let that = this;
            let name = that.groupName ? that.groupName.replace(regfunc.trim, "") : '';
            if (!regfunc.rGroupName.test(name)) {
                that.$MessageWarning(that.$t('message.groupnameTips'));
                return
            }
            let dialogData = that.dialogData;
            let data = {
                uId: that.userInfo.id,
                gId: dialogData.Gid,
                groupName: that.groupName
            }
            updateGroup(data).then(res => {       //修改群组名称
                if (res.data.code == 200) {
                    that.dialogTableVisible = false;
                    this.$emit('successEditqz');
                    this.$message({
                        message: that.$t('message.message.Successfullysaved'),
                        type: "success",
                        center: true,
                        offset: 450
                    });
                } else {
                    that.$MessageWarning(res.data.msg);
                }
            })
        },
        changeGroup(type) {      //添加 删除
            let that = this;
            let data = {
                uId: that.userInfo.id,
                gId: that.dialogData.Gid
            }
            let arr = [];
            // let uids;
            if (type <= 2) {
                arr = that.InGroupUser.selectUser;
                if (that.InGroupUser.userArray.length <= 0) {
                    that.$MessageWarning(that.$t('message.message.Removalfailed'));
                    return
                }
                if (type == 2 && arr.length == 0) {
                    that.$MessageWarning(that.$t('message.message.Pleaseselectuserremoved'));
                    return
                }
                data.uids = type == 1 ? that.InGroupUser.userArray.map(item => item.User_ID).join() : arr.map(item => item.User_ID).join();
                that.getRemoveMember(data);
            } else {
                let arr = that.noGroupUser.selectUser;
                if (that.noGroupUser.userArray.length <= 0) {
                    that.$MessageWarning(that.$t('message.message.Addingfailed'));
                    return
                }
                if (type == 3 && arr.length == 0) {
                    that.$MessageWarning(that.$t('message.message.Pleaseselecttheuseryouwanttoadd'));
                    return
                }
                let array = type == 3 ? arr : that.noGroupUser.userArray;
                let members = [];
                array.forEach(item => {
                    members.push({
                        user_id: item.User_ID,
                        id: item.id
                    })
                })
                data.members = members;
                that.getAddMember(data);
            }
        },
        getAddMember(data) {        //添加不在组用户
            let that = this;
            addMember(data).then(res => {
                if (res.data.code == 200) {
                    this.$message({
                        message: this.$t('message.message.Successfullyadded'),
                        type: "success",
                        center: true,
                        offset: 450
                    });
                    that.noGroupUser.searchName = "";
                    that.InGroupUser.searchName = "";
                    that.InGroupUser.selectUser = [];
                    that.noGroupUser.selectUser = [];
                    that.getQueryMember();
                    that.getQueryUnMember();
                } else {
                    that.$MessageWarning(res.data.msg);
                }
            })
        },
        getRemoveMember(data) {     //移除在组用户
            let that = this;
            removeMember(data).then(res => {
                // console.log(res)
                if (res.data.code == 200) {
                    this.$message({
                        message: this.$t('message.message.Successfullyremoved'),
                        type: "success",
                        center: true,
                        offset: 450
                    });
                    that.noGroupUser.searchName = "";
                    that.InGroupUser.searchName = "";
                    that.InGroupUser.selectUser = [];
                    that.noGroupUser.selectUser = [];
                    that.getQueryMember();
                    that.getQueryUnMember();
                } else {
                    that.$MessageWarning(res.data.msg);
                }
            })
        },
        bindSearch(type) {      //搜索
            if (type == 1) {      //搜索再组成员
                this.getQueryMember();
            } else if (type == 2) {  //搜索不再组成员
                this.getQueryUnMember();
            }
        },
        bindEmpty(type) {        //清空输入框值
            if (type == 1) {
                this.InGroupUser.searchName = "";
            } else if (type == 2) {
                this.noGroupUser.searchName = "";
            }
        },
        async getQueryMember() {       //搜索再组成员
            let that = this;
            let data = {
                uId: that.userInfo.id,
                gId: that.dialogData.Gid
                // keyword: this.InGroupUser.searchName
            }
            if (that.InGroupUser.searchName != "" && that.InGroupUser.searchName != null) {
                data.keyword = that.InGroupUser.searchName
            }
            await queryMember(data).then(res => {
                // console.log(res)
                if (res.data.code == 200) {
                    res.data.data.forEach(item => {
                        // if (that.groupId == 1) {
                        item.label = `${item.nickname}(${item.User_Account})`;
                        // } else {
                        //     item.label = item.nickname;
                        // }
                    })
                    that.InGroupUser.userArray = res.data.data;
                    if (that.groupId == 3) {
                        that.getQueryGroup();
                    }
                } else {
                    that.$MessageWarning(res.data.message);
                }
            })
        },
        getQueryUnMember() {     //搜索不再组成员
            let that = this;
            let data = {
                gId: that.dialogData.Gid,
                uId: that.userInfo.id,
                keyword: this.noGroupUser.searchName
            }
            queryUnMember(data).then(res => {
                // console.log(res)
                if (res.data.code == 200) {
                    res.data.data.forEach(item => {
                        item.label = `${item.nickname}(${item.User_Account})`;
                    })
                    that.noGroupUser.userArray = res.data.data;
                } else {
                    that.$MessageWarning(res.data.message);
                }
            })
        },
        getQueryGroup() {       //查询群组详情
            let that = this;
            queryGroup({
                id: that.dialogData.Gid
            }).then(res => {
                let data = res.data.data;
                if (res.data.code == 200) {
                    // console.log(res)
                    that.hostId = data.Cg_Dispatcher;
                    let InGroupUser = that.InGroupUser.userArray.find(item => item.User_ID == data.Cg_Dispatcher);
                    if (InGroupUser) {
                        that.groupHost = InGroupUser.nickname;
                    }
                } else {
                    that.$MessageWarning(res.data.message);
                }
            })
        },
        handleNodeClick(id) {        //主持人选中
            console.log(id)
            let data = {
                uId: this.userInfo.id,
                gId: this.dialogData.Gid,
                hostId: id
            }
            updaetHost(data).then(res => {
                if (res.data.code == 200) {
                    this.getQueryGroup();
                    this.$message({
                        message: this.$t('message.message.Successfullysethost'),
                        type: "success",
                        center: true,
                        offset: 450
                    });
                } else {
                    this.$MessageWarning(res.data.msg);
                }
            })

        },
        deleteHost() {       //删除主持人
            let data = {
                uId: this.userInfo.id,
                gId: this.dialogData.Gid,
                hostId: 0
            }
            updaetHost(data).then(res => {
                if (res.data.code == 200) {
                    this.groupHost = "";
                    this.hostId = 0;
                    this.getQueryGroup();
                    let array = [];
                    this.InGroupUser.userArray.forEach(item => {
                        item.disabled = false;
                        array.push(item)
                    })
                    this.InGroupUser.userArray = array;
                    this.$message({
                        message: this.$t('message.message.Successfullydeleted'),
                        type: "success",
                        center: true,
                        offset: 450
                    });
                }
            })
        },
        deweight(arr) {      //数组去重
            let de_arr = []
            arr.forEach(i => {
                let isTrue = de_arr.every((j) => j.id != i.id);
                isTrue ? de_arr.push(i) : ''
            });
            return de_arr;
        },
        focus() {    //获取焦点
            this.$store.state.isFocusing = true;
        },
        blur() {     //失去焦点
            this.$store.state.isFocusing = false;
        }
    },
    mounted() {
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        this.groupName = this.dialogData.Gname;
        this.getQueryMember();
        this.getQueryUnMember();
    },
    filters: {

    },
    watch: {

    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
::-webkit-scrollbar {
    width: 6px;
    height: 100%;
    background-color: #ccc;
}

::-webkit-scrollbar-track {
    width: 6px;
    background-color: #4D4D4D;
}

::-webkit-scrollbar-thumb {
    background-color: #727171;
}

/deep/.el-dialog {
    background: #4D4D4D;
    margin-top: 20vh;

    &__title {
        color: #fff;
        font-size: 16px;
    }

    .el-dialog__headerbtn .el-dialog__close {
        color: var(--main-color-tow) !important;
    }

    .dialog {
        width: 94%;
        background-color: #383838;
        margin: auto;
        color: #fff;
        border: 1px solid #6e6d6d;

        &_block {
            margin-bottom: 15px;

            .el-input--prefix .el-input__inner {
                padding-left: 15px;
            }

            .el-icon-date:before {
                display: none;
            }

            .el-input__inner {
                width: 100%;
                height: 35px;
                background-color: #4D4D4D;
                border: 1px solid #4D4D4D;
                color: #fff;
            }
        }

        &_text {
            font-size: 14px;
        }

        &_left {
            // width: 120px;
            min-width: 130px;
            height: 100%;
            background-color: #4D4D4D;

            &_groupBg {
                width: 100%;
                height: 75px;
                line-height: 75px;
                text-align: center;
            }

            &_bg {
                width: 120px;
                height: 120px;
                line-height: 120px;
                text-align: center;
            }

            &_bgOne {
                background-color: #383838;
            }
        }

        &_right {
            flex: 1;
            height: 100%;
            margin: auto;

            &_top {
                width: 90%;
                margin: 15px auto 25px auto;
                height: 340px;
                overflow: auto;
                border: 1px solid #6e6d6d;

                &_dh {
                    display: flex;
                    align-items: center;
                    position: relative;
                    width: 97%;
                    margin: 15px auto;

                    &_left {
                        border-style: solid;
                        border-color: transparent;
                        border-width: 6px 6px 6px 0px;
                        border-right-color: #2e7777;
                    }

                    &_right {
                        // flex: 1;
                        // width: 80%;
                        min-height: 40px;
                        background-color: #2e7777;
                        border-radius: 5px;
                        font-size: 14px;

                        &_text {
                            padding: 5px 10px;
                            line-height: 25px;
                        }
                    }

                    &_rightOne {
                        width: 149px;
                        min-height: 55px;
                        background-color: #2e7777;
                        border-radius: 10px;
                        font-size: 14px;

                        img {
                            width: 15px;
                            height: 21px;
                            margin-left: 15px;
                        }

                        span {
                            margin-left: 10px;
                        }
                    }

                    &_text {
                        // position: absolute;
                        // left: calc(80% + 16px);
                        // bottom: 0px;
                        min-width: 50px;
                        color: #727171;
                        margin-top: 20px;
                        margin-left: 10px;
                    }
                }
            }

            &_bottom {
                width: 90%;
                margin: auto;
                height: 85px;
                border: 1px solid #6e6d6d;

                .el-textarea__inner {
                    // width: 100%;
                    padding: 15px !important;
                    // height: 85px;
                    background-color: transparent;
                    color: #fff;
                    border: none;
                    font-size: 14px;
                    resize: none;
                }
            }

            &_ly {
                width: 90%;
                margin: auto;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &_img {
                width: 302px;
                height: 66px;
                // margin: auto;
                background: url("../../assets/gb/lyaj.png");
                background-size: 100% 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &_delet {
                width: 88px;
                height: 35px;
                line-height: 35px;
                text-align: center;
                background-color: var(--main-color);
                opacity: 0.9;
                border: 1px solid var(--main-color-tow);
                color: #ffffff;
                margin-left: 35px;
            }
        }

        // 群组编辑
        &_group {
            width: 92%;
            margin: auto;

            &_top {
                width: 100%;
                height: 75px;
                border-bottom: 1px solid #6e6d6d;

                .el-input__inner {
                    width: 100%;
                    height: 40px;
                    background-color: #4D4D4D;
                    border: 1px solid #4D4D4D;
                    color: #fff;
                    border-radius: 0px !important;
                }
            }

            &_red {
                font-size: 12px;
                color: red;
            }

            &_input {
                width: 285px;
                height: 40px;
                margin: 0px 10px;
            }

            &_user {
                width: 100%;
                height: 370px;

                &_text {
                    margin-right: 15px;
                }

                &_xz {
                    flex: 1;
                    display: flex;

                    &_box {
                        width: 280px;
                        height: 370px;

                        &_title {
                            width: 100%;
                            height: 35px;
                            line-height: 35px;
                            text-align: center;
                            color: var(--main-color-tow);
                        }
                    }
                }

                &_but {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;

                    img {
                        width: 23px;
                        height: 19px;
                        margin-top: 25px;
                    }
                }

                &_search {
                    width: 100%;
                    height: 100%;
                    border: 1px solid #6e6d6d;
                    overflow: hidden;

                    &_tree {
                        width: 100%;
                        height: calc(100% - 45px);
                        overflow: auto;
                        margin-top: 10px;
                    }
                }

                &_gl {
                    flex: 1;
                    display: flex;
                    margin-top: 20px;

                    &_box {
                        width: 215px;
                        text-align: center;

                        &_title {
                            width: 100%;
                            height: 35px;
                            line-height: 35px;
                            text-align: center;
                            color: var(--main-color-tow);
                        }

                        &_content {
                            width: 100%;
                            height: 22px;
                            line-height: 22px;
                            font-size: 14px !important;
                        }

                        &_contentOne {
                            border: 1px solid #6e6d6d;
                            border-radius: 2px;
                        }
                    }
                }
            }

            &_delet {
                min-width: 108px;
                height: 35px;
                line-height: 35px;
                text-align: center;
                background-color: var(--main-color);
                opacity: 0.9;
                border: 1px solid var(--main-color-tow);
                color: #ffffff;
                margin-left: 10px;
                padding: 0px 5px;
            }
        }
    }

}

.dialog_group_user_gl_box_content /deep/.el-input__inner {
    height: 22px !important;
    line-height: 22px !important;
    background-color: transparent;
    border: none;
    color: #fff;
    text-align: center;
}

.dialog_group_user_gl_box_content /deep/.el-select .el-input .el-select__caret {
    color: var(--main-color);
}

.dialog_group_user_gl_box_content /deep/.el-input__icon {
    line-height: 25px;
}

/deep/.el-input.is-disabled .el-input__inner {
    background: transparent;
}

/deep/.el-input__icon {
    display: none;
}

.search_one {
    /deep/.el-input__inner {
        width: 100%;
        // width: 100%;
        height: 28px;
        line-height: 28px;
        background-color: #333333;
        color: #ffffff;
        border: none;
        border-radius: 0px;
        padding: 0px 10px;
    }
}

.search_tow {
    width: 415px;
    height: 45px;
    border: 1px solid var(--main-color);
    background-color: #383838 !important;
    border-radius: 2px;

    /deep/.el-input__inner {
        width: 100%;
        // width: 100%;
        height: 45px !important;
        line-height: 45px !important;
        background-color: #383838 !important;
        color: #ffffff;
        border: none !important;
        border-radius: 0px;
        padding: 0px 10px;
    }

    &_input {
        flex: 1;
        height: 100%;
        background-color: #383838;
        color: #ffffff !important;

        img {
            width: 12px;
            height: 12px;
            margin: 0px 15px;
        }
    }

    &_but {
        width: 55px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        background-color: var(--main-color);
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 17px;
            height: 17px;
        }
    }
}

.search {
    width: 100%;
    height: 28px;
    background-color: var(--main-color);
    border: 1px solid var(--main-color);

    &_input {
        flex: 1;
        height: 100%;
        background-color: #333333;
        color: #ffffff !important;


        img {
            width: 12px;
            height: 12px;
            margin: 0px 15px;
        }
    }

    &_but {
        width: 40px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        background-color: var(--main-color);
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 17px;
            height: 17px;
        }
    }
}


.bottom {
    width: 100%;
    text-align: center;
    padding: 15px 0px;

    /deep/.gb.el-button {
        width: 89px;
        height: 38px;
        border: 1px solid #fff !important;
        background-color: transparent !important;
        color: #fff !important;
    }

    /deep/.bc.el-button {
        width: 89px;
        height: 38px;
        border: 1px solid var(--main-color-tow) !important;
        background-color: transparent !important;
        color: var(--main-color-tow) !important;
        margin-right: 30px;
    }
}


/deep/.el-tree {
    background-color: transparent;
    color: #fff;

    .el-tree-node__content:hover,
    .el-upload-list__item:hover {
        background-color: transparent !important;
    }

    .el-tree-node:focus,
    .is-expanded:focus,
    .is-focusable:focus {
        background-color: transparent !important;
    }

    .el-tree-node.is-current>.el-tree-node__content {
        background: transparent !important;
    }

    .el-tree-node__expand-icon.expanded {
        color: var(--main-color);
    }

    .el-checkbox__inner {
        background-color: #343434 !important;
    }

    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner,
    .el-checkbox__inner:hover {
        border-color: #dcdfe6;
    }

    .el-checkbox__inner::after {
        border-color: var(--main-color);
    }

    .el-tree-node:focus>.el-tree-node__content {
        background: transparent !important;
    }

    .el-tree-node__content>.el-tree-node__expand-icon {
        padding: 0px;
    }

    .el-tree-node__content {
        line-height: 28px;
    }

    .el-tree-node__label {
        width: 230px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

/deep/.el-dialog--center .el-dialog__body {
    padding: 0px !important;
}

/deep/.el-radio-group {
    display: flex;
    flex-direction: column;

    .el-radio {
        color: #ffffff;
        margin: 0px 10px 5px 10px;
        display: flex;
        align-items: center;

        &>.el-radio__label {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 5px 0px 5px 10px;
        }
    }

    .el-radio__input.is-checked .el-radio__inner {
        border-color: var(--main-color);
        background: var(--main-color);
    }

    .el-radio__input.is-checked+.el-radio__label {
        color: var(--main-color);
    }

    .el-radio__inner:hover {
        border-color: var(--main-color);
    }
}



.flex {
    display: flex;
    align-items: center;
}

.flex_between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex_direction {
    //垂直居中
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.demonstration {
    width: 120px;
}
</style>