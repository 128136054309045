<template>
    <div>
        
    </div>
</template>

<script>
export default {
    beforeCreate(){
        let sss=this.$route.params.url
        this.$router.push(sss)//接收他的参数
    }
}
</script>

<style lang="less" scoped>

</style>