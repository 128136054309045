import request from '@/api/request';
import https from '@/api/https';
// 获取录音记录
export async function querySoundRecord(data) {
    return await https({
        url: '/v1/soundRecord/querySoundRecord',
        method: 'get',
        params: data
    })
}
// 获取录音文件
export async function queryAudio(data) {
    return await https({
        url: '/v1/queryAudio',
        method: 'post',
        data: data
    })
}

// 获取sos记录
export async function querySOSRecord(data) {
    return await request({
        url: '/record/querySOSRecord',
        method: 'post',
        data: data
    })
}
// 获取广播记录
export async function queryBroadcastRecord(data) {
    return await request({
        url: '/record/queryBroadcastRecord',
        method: 'post',
        data: data
    })
}
// 获取群组下拉
export async function queryGroupName(data) {
    return await request({
        url: 'group/queryGroupName',
        method: 'post',
        data: data
    })
}

// 获取用户下拉
export async function queryUserName(data) {
    return await request({
        url: 'user/queryUserName',
        method: 'post',
        data: data
    })
}
