import { Message } from 'element-ui'

let _common = {}

_common = {
  MessageError: MessageError,
  MessageInfo: MessageInfo,
  MessageSuccess: MessageSuccess,
  MessageWarning: MessageWarning
}

export default _common

export function MessageError(text = '错误') {
  Message({
    message: text,
    type: 'error',
    // offset: 200,
    duration: 2000
  })
}
export function MessageInfo(text = '取消', offset) {
  Message({
    message: text,
    type: 'info',
    offset: offset ? offset : 20,
    // offset: 200,
    duration: 2000
  })
}
export function MessageSuccess(text = '成功', offset) {
  Message({
    message: text,
    type: 'success',
    offset: offset ? offset : 20,
    duration: 2000
  })
}

export function MessageWarning(text = '警告', duration) {
  Message({
    message: text,
    type: 'warning',
    // offset: 200,
    duration: duration ? duration : 2000
  })
}

export function customMessageError(text = '错误') {   //自定义提示
  Message({
    // showClose: true,
    iconClass: " ",
    dangerouslyUseHTMLString: true,
    message: `<div>${text}</div>`,
    duration: 2000,
    // offset: 90,
    customClass: "tipsMessageError"
  });
}
