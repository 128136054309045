<template>
  <div class="box" id="wrapp">
    <!-- 右侧 -->
    <!-- $store.state.navId <= 2 -->
    <div :class="['overall', $route.name != 'baiduMap' && $route.name != 'enclosureMap' ? 'overall_one' : '']"
      v-show="isDataAnalysis">
      <!-- 右侧数据分析 -->
      <div class="right" :style="$store.state.language == 'en' ? 'width: 315px;' : ''">
        <div class="right_Statistics right_border">
          <div class="right_box">
            <div class="right_title">{{ $t('message.Statisticstoday') }}</div>
            <div class="right_nuber">
              <div class="right_bg" v-for="(item, index) in statistics.onlineUserTotal" :key="index">
                {{ item }}
              </div>
              <div style="font-size:12px;color: #6ab5fd;letter-spacing: 1px;">
                {{ $t('message.Currentonlineusers') }}
              </div>
            </div>
            <div class="right_bottom flex_align">
              <div class="right_bottom_box">
                <div class="right_bottom_title">{{ $t('message.fixedGroup') }}</div>
                <div class="right_bottom_num">{{ statistics.fixedGroup }}</div>
              </div>
              <div class="right_bottom_box">
                <div class="right_bottom_title">{{ $t('message.Totalnumberofusers') }}</div>
                <div class="right_bottom_num">{{ statistics.userTotal }}</div>
              </div>
              <!-- <div class="right_bottom_box">
                <div class="right_bottom_title">{{ $t('message.Fencerecord') }}</div>
                <div class="right_bottom_num">
                  {{ statistics.enclosureCount }}
                </div>
              </div>
              <div class="right_bottom_box">
                <div class="right_bottom_title">{{ $t('message.SOSrecord') }}</div>
                <div class="right_bottom_num" style="color:#ff0000;">
                  {{ statistics.sosCount }}
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div :class="[
          'right_border',
          $route.name != 'baiduMap' && $route.name != 'enclosureMap' ? 'customer_height' : 'right_customer'
        ]">
          <div class="right_box">
            <div class="right_title">{{ $t('message.Grouponlineusers') }}</div>
            <div
              :class="[$route.name != 'baiduMap' && $route.name != 'enclosureMap' ? 'right_container' : 'right_container_tow']">
              <div class="right_name flex_align" v-for="(item, index) in groupArray" :key="index">
                <div class="right_num_bg" :class="index == 0
                  ? 'bg_one'
                  : index == 1
                    ? 'bg_tow'
                    : index == 2
                      ? 'bg_three'
                      : 'bg_four'
                  ">
                  {{ index + 1 }}
                </div>
                <div class="right_flex">{{ item.label }}</div>
                <div class="right_num_color">
                  {{ item.children | groupNum }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right_contrast right_border">
          <div class="right_box">
            <div class="right_title" :style="$store.state.language == 'en' ? 'font-size: 13px;padding: 5px 0px;' : ''">
              {{
                $t('message.Comparison') }}</div>
            <div class="right_dub flex_align" v-for="(item, index) in sevenDaysData" :key="index">
              <template v-if="index <= 6">
                <div class="right_tian">
                  {{ item.days }}
                </div>
                <div class="right_flex">
                  <!-- color="#6ab5fd"  -->
                  <el-progress :text-inside="true" :show-text="false" :stroke-width="10" :percentage="item.percentage">
                  </el-progress>
                </div>
                <div style="color:#fff;">{{ item.count }}</div>
              </template>
            </div>
          </div>
        </div>
        <div class="right_contrast right_border">
          <div class="right_box">
            <div class="right_title">{{ $t('message.warningcomparison') }}</div>
            <div id="main" :style="{ height: echartsData.height, width: echartsData.width }"></div>
          </div>
        </div>
      </div>
      <!-- 数据分析结束 -->
    </div>
    <div class="bottom">
      <div class="bottom_left">
        <div class="bottom_popup" v-if="$store.state.userSpeech.isShow || $store.state.userSpeech.isBool">
          <div v-if="$store.state.userSpeech.isShow">{{ $store.state.userSpeech.title }}</div>
          <div v-else>{{ $store.state.userSpeech.userTitle }}</div>
          <div class="triangle">
            <div class="triangle_border"></div>
          </div>
        </div>
        <img slot="reference" @dragstart.prevent class="bottom_ptt mouse" @mousedown="PTT(1)" @mouseup="PTT(2)"
          v-if="userState.isTalkButton == 1" src="../assets/PTT.png" alt="" @mouseleave="PTT(2)" />
      </div>
      <div class="bottom_gj"><img class="bottom_tz mouse" @click="notice" src="../assets/tz.png" alt=""></div>
    </div>
    <!-- <div class="sos" v-if="$store.state.sosPushInfo.length > 0" style="z-index: 17;">
      <div class="sos_head flex_align">
        <img src="@/assets/sos/sos_hb.png" style="width: 74px;height: 74px;" alt="" />
        <div class="sos_Circular">
          {{ $store.state.sosPushInfo.length | ninetyNine }}
        </div>
        <img class="sos_gb" @click="closeSos(1)" src="@/assets/sos/sos_gb.png" alt="" />
      </div>
      <div class="sos_content">
        <div class="sos_content_sl" style="margin-bottom: 15px;">
          <span>{{ $t('message.Name') }}：</span>{{
      $store.state.sosPushInfo[$store.state.sosIndex].sosContent
        .sosSendName
    }}
        </div>
        <div class="sos_content_address" style="margin-bottom: 15px;">
          <span>{{ $t('message.Address') }}：</span>{{
      $store.state.sosPushInfo[$store.state.sosIndex].sosContent
        .sosLocationAt ? $store.state.sosPushInfo[$store.state.sosIndex].sosContent
          .sosLocationAt.address : ''
    }}
        </div>
        <div class="sos_content_sl">
          <span>{{ $t('message.Time') }}：</span>{{
      $store.state.sosPushInfo[$store.state.sosIndex].sosContent.sosStamp
      | timeTransformation
    }}
        </div>
      </div>
      <div class="sos_bottom flex_align" style="margin-bottom: 15px;">
        <div class="sos_but" @click="seeOssPosition" style="background-color: red;">
          {{ $t('message.Check') }}
        </div>
        <div class="sos_but" v-if="$store.state.sosPushInfo.length > 1" @click="next"
          style="margin-left: 25px;background-color: transparent;border: 2px solid #aaaaaa;height: 41px;line-height: 41px;color: #aaaaaa;">
          {{ $t('message.next') }}
        </div>
      </div>
    </div> -->
    <!-- 越界 -->
    <!-- <div class="sos" style="width: 335px;" v-if="$store.state.enclosureTc.length > 0">
      <div class="sos_head flex_align">
        <img src="@/assets/sos/sos_yj.png" style="width: 74px;height: 74px;" alt="" />
        <img class="sos_gb mouse" @click="closeSos(2)" style="top: 25px;" src="@/assets/sos/sos_gb.png" alt="" />
      </div>
      <div class="sos_content">
        <div class="sos_content_sl" style="margin-bottom: 15px;">
          <span>{{ $t('message.outofboundsperson') }}：</span>
          {{ $store.state.enclosureTc[0].Info.userName }}
        </div>
        <div class="sos_content_address" style="margin-bottom: 15px;">
          <span>{{ $t('message.fence') }}：</span>{{ $store.state.enclosureTc[0].Info.WLName }}
        </div>
        <div class="sos_content_sl">
          <span>{{ $t('message.Time') }}：</span>{{ $store.state.enclosureTc[0].Info.Time | timeTransformationTow }}
        </div>
      </div>
    </div> -->
    <!-- 广播 -->
    <div class="sos" style="width:360px;" v-if="$store.state.gbInfoVoice.length > 0">
      <div class="sos_head flex_align">
        <img src="@/assets/sos/gb.png" style="width: 74px;height: 74px;" alt="">
        <img class="sos_gb mouse" @click="closeSos(3)" src="@/assets/sos/sos_gb.png" alt="">
      </div>
      <div class="sos_content">
        <div class="sos_content_sl" style="margin-bottom: 15px;">
          <span>广播人：</span>{{ $store.state.gbInfoVoice[0].from_name }}
        </div>
        <div class="sos_content_sl">
          <span>广播时间：</span>{{ $store.state.gbInfoVoice[0].send_time | timeTransformation }}
        </div>
      </div>
      <div class="sos_bottom flex_align" style="margin-bottom: 15px;">
        <div class="sos_but mouse" @click="listenInGb" style="background-color: #ffb400;color: #333333;">收听</div>
      </div>
    </div>
    <!-- 视频 -->
    <div class="sos" style="width:360px;"
      v-if="$store.state.videoCallInfo.echoList.length > 0 && $store.state.videoCallInfo.switchVideo == 0">
      <div class="sos_head flex_align">
        <img src="@/assets/sos/sphc.png" style="width: 74px;height: 74px;" alt="">
        <img class="sos_gb mouse" @click="seeEchoList(1)" src="@/assets/sos/sos_gb.png" alt="">
      </div>
      <div class="sos_content">
        <div class="sos_content_sl" style="margin-bottom: 15px;">
          <span>回传人：</span>{{
            $store.state.videoCallInfo.echoList[$store.state.videoCallInfo.echoList.length - 1].name
          }}
        </div>
        <div class="sos_content_sl">
          <span>回传时间：</span>{{
            $store.state.videoCallInfo.echoList[$store.state.videoCallInfo.echoList.length - 1].inviteTime |
            timeTransformation
          }}
        </div>
      </div>
      <div class="sos_bottom flex_align" style="margin-bottom: 15px;">
        <div class="sos_but mouse" @click="seeEchoList(0)" style="background-color: #2776c3;color: #333333;">查看</div>
      </div>
    </div>
    <div class="giveAnAlarm flex_column" v-if="isOpen">
      <div class="giveAnAlarm_title">{{ $t('message.Alarminformation') }}</div>
      <div class="giveAnAlarm_tab flex_align">
        <div class="giveAnAlarm_tab_item flex_one" :class="alarmArrIndex == index ? 'active' : ''"
          v-for="(item, index) in alarmArr" :key="index" @click.prevent="selectedTab(index)">
          <div class="giveAnAlarm_tab_item_box flex_align" :class="alarmArrIndex == index ? 'red' : ''">
            <!-- 'url(' + alarmArrIndex == index ? item.bg1 : item.bg + ')'  -->
            <div class="giveAnAlarm_tab_item_box_bg" :class="alarmArrIndex == index ? 'alarm1' : 'alarm'"
              :style="{ backgroundImage: `url(${alarmArrIndex == index ? item.bg1 : item.bg})` }"></div>
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="giveAnAlarm_gb mouse" @click="cloneWarning">×</div>
      <div class="giveAnAlarm_info flex_one mouse">
        <template v-if="alarmArrIndex == 0">
          <div v-for="(item, index) in sosList" :key="index" class="flex_align" @click="jumptoSosMarker(item, index)">
            <div class="giveAnAlarm_info_Serial">
              {{ sosList.length - index }}
            </div>
            <div class="giveAnAlarm_info_one ellipsis_app flex_one" v-if="item.sendName">
              <el-tooltip style="width:100%;" :content="item.sendName" placement="left">
                <span>{{ item.sendName }}</span>
              </el-tooltip>
            </div>
            <div style="margin: 0px 10px;">{{ item.sos_start_time | timeTransformationOne }}</div>

            <div class="giveAnAlarm_info_cl" @click.stop="handleSos(item, index)">{{ $t('message.dealwith') }}</div>
            <div style="margin-right: 15px;"></div>
          </div>
        </template>
        <template v-else>
          <div v-for="(item, index) in fenceList" :key="index" class="flex_align" @click="jumptoSosMarker(item, index)">
            <div class="giveAnAlarm_info_Serial">
              {{ index + 1 }}
            </div>
            <div class="giveAnAlarm_info_one ellipsis_app" v-if="item.userName">
              <el-tooltip style="width:100%;" :content="item.userName" placement="left">
                <span>{{ item.userName }}</span>
              </el-tooltip>
            </div>
            <div style="margin: 0px 10px;">{{ item.alarmTimestamp | timeTransformationOne }}</div>
            <div class="ellipsis_app flex_one">
              <el-tooltip style="width:100%;" :content="item | fenceTips" placement="left">
                <span>{{ item | fenceTips }}</span>
              </el-tooltip>
            </div>
            <div style="margin-right: 15px;"></div>
          </div>
        </template>
      </div>
      <div class="giveAnAlarm_info_jl" v-if="alarmArrIndex == 0" @click="jumptoRecord">{{ $t('message.sosrecord') }}>
      </div>
      <div class="giveAnAlarm_tips">
        {{ $t('message.AlarminformationTips') }}
      </div>
    </div>
    <!-- <audio controls autoplay src='https://apidev.xin-ptt.com:9001/mms-ptt01/api/v1/media/download?user=3897&uuid=8f877ac6a5c345a3a5c0d8e8087deb43'></audio> -->
  </div>
</template>

<script></script>
<script>
import {
  getNearly7DaysOnline,
  getAlarmByMonth,
  getAlarmLogCount
} from "../administration/dispatchLntercom.js";
import {querySOSRecord} from "@/administration/history.js";
// import { EleResize } from "../administration/esresize.js";
import EventBus from "@/eventBus/eventBus.js";
import sosWav from "@/method/wav/sos_received.wav";
import fence from "@/method/wav/fence_receiced.wav";
import { _throttle,_debounce } from "@/method/utils.js";
const synth = window.speechSynthesis;
const msg = new SpeechSynthesisUtterance();
import beasconfig from "@/api/beasconfig.js";
// const userState = JSON.parse(sessionStorage.getItem("userState"));
import textGb2x from "../assets/sos/textGb2x.png";
import textGb2x1 from "../assets/sos/textGb12x.png";
import moment from 'moment'; 
import axios from "axios";
import qs from 'qs';
let seft;
export default {
  components: {},
  data() {
    return {
      sevenDaysData: [], //七天数据
      userInfo: {}, //用户数据
      echartsData: {
        //echarts数据
        width: "305px",
        height: "190px"
      },
      statistics: {
        //統計
        onlineUserTotal: [0, 0, 0, 0, 0], //在线用户总数
        userTotal: 0, //用户总数
        fixedGroup: 0, //固定群组数量
        enclosureCount: 0, //电子围栏记录数
        sosCount: 0 //sos记录数
      },
      visible: true,
      timeNum: 0,
      temporaryTime: null,
      isOpen:false,
      sosList:[],   //sos告警
      fenceList:[],    //围栏告警
      giveanalarmType:0,
      isPlay:false,    //是否播放
      alarmArr:[
        {
          name:'SOS',
          bg:require('../assets/sos/alarm_sos.png'),
          bg1:require('../assets/sos/alarm_sos1.png')
        },
        {
          name: this.$t('message.fence'),
          bg:require('../assets/sos/alarm_yj.png'),
          bg1:require('../assets/sos/alarm_yj1.png')
        }
      ],
      alarmArrIndex:0
    };
  },
  props: {
    isDataAnalysis: {
      ///数据分析
      type: Boolean,
      default: true
    },
    userState: {
      // type: Object,
      default: {
        isBindRoad: 0, // 是否绑路 0 否 1是
        sosVoice: 0, //sos声音开关
        speechRecognition: 0, //语音识别
        fullDuplex: 0, //全双工
        fullDuplexAudioTime: 0,
        isTalkButton: 0, //对讲按键显示
        enclosureVoice: 0 //围栏声音
      }
    }
  },
  created() {
    // console.log(JSON.parse(sessionStorage.getItem("groupArray")))
    let that = this;
    // that.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    that.getNearly7DaysOnline();
    that.getAlarmLogCount();
    that.getQuerySOSRecord();
    that.getTransboundList();
    EventBus.$on("sosTips", function(type) {
      //语音对讲
      that.getAlarmLogCount();
      if(type == 'enclosure'){
        that.alarmArrIndex = 1;
        that.getTransboundList();
      }
    });
    EventBus.$on("controlGb", function(type) {
      //控制广播
      if (type == 1) {
        that.handleSpeak();
      } else {
        that.handleStop();
      }
    });
    EventBus.$on("cloneSosInfo", function(msgid) {
       let index = that.sosList.findIndex(item => item.sos_msg_id == msgid);
       if(index >= 0){
        that.sosList.splice(index, 1);
       }
       if(that.sosList.length === 0){
          that.suspendPlay();
       }
    });
  },
  beforeCreate: function () { 
        seft = this;
  },
  mounted() {
    window.closeMaker = this.closeMaker;
    this.hzEcharts();
  },
  beforeDestroy() {
    this.suspendPlay();
    if (
      this.$store.state.gbInfoText.length > 0 &&
      this.$store.state.calltThePolice != null || this.$store.state.gbInfoVoice.length > 0
    ) {
      this.suspendPlay1();
      this.handleStop();
    }
    this.$store.state.gbInfoVoice = [];
    this.$store.state.gbInfoText = [];
    this.$store.state.sosPushInfo = [];
    this.$store.state.sosCallthepolice = false;
    this.$store.state.enclosureTc = [];
    this.$message.closeAll(); //关闭弹出框提示
    EventBus.$off("controlGb");
    EventBus.$off("sosTips");
    EventBus.$off("cloneSosInfo");
  },
  filters: {
    fenceTips(item){

        return `${item.efType == 0?seft.$t('message.leave'):seft.$t('message.Enter')}${item.efName}${seft.$t('message.fence')}`;
    },
    // infoNotice: function(item) {    //消息通知
    //   let title;
    //   let type = item.recordType;
    //   if(type == 1){    //1sos
    //     title = seft.$t('message.initiatesos');
    //   }else if(type == 2){    //电子围栏
    //     title = `${item.efType == 0?seft.$t('message.leave'):seft.$t('message.Enter')}${item.EF_Name}${seft.$t('message.fence')}`;   
    //   }else if(type == 3){    //视频
    //     let text = item.type == 101 ? seft.$t('message.Forcedvideo') : item.type == 200 ? seft.$t('message.videocall') : seft.$t('message.Videopostback');
    //     title = text;
    //   }
    //   return title
    // },
    groupNum: function(data) {
      return data.filter(item => item.online == 3).length;
    },
    ninetyNine: function(value) {
      if (value > 99) {
        value = 99;
      }
      return value;
    },
    timeTransformationOne: function(value) {
      let time = new Date(); //获取当前时间  Tue May 11 2021 18:42:51 GMT+0800 (中国标准时间)
      let time1 = moment(time).format("YYYY-MM-DD");
      let time2 = moment(value).format("YYYY-MM-DD");
      return time1 > time2?moment(value).format("YYYY-MM-DD"):moment(value).format("HH:mm:ss");
    },
    timeTransformationTow: function(value) {
      let time = new Date(value); //获取当前时间  Tue May 11 2021 18:42:51 GMT+0800 (中国标准时间)
      time.setHours(time.getHours() + 8);
      return moment(time).format("YYYY-MM-DD HH:mm:ss");
    },
    timeTransformation: function(value) {
      let time = new Date(value); //获取当前时间  Tue May 11 2021 18:42:51 GMT+0800 (中国标准时间)
      let year = time.getFullYear(); //获取年 2021
      let month = time.getMonth() + 1; //获取月  5
      let day = time.getDate(); //获取天  11
      let h = time.getHours(); //获取小时  18
      let m = time.getMinutes(); //获取分钟  42
      let s = time.getSeconds(); //获取秒  51
      if (h >= 0 && h <= 9) {
        h = "0" + h;
      }
      if (m >= 0 && m <= 9) {
        m = "0" + m;
      }
      if (s >= 0 && s <= 9) {
        s = "0" + s;
      }
      return year + "-" + month + "-" + day + "-" + h + ":" + m + ":" + s;
    }
  },
  methods: {
    jumptoRecord(){   //跳转记录
      this.$router.push("/history/sosrecord");
    },
    jumptoSosMarker(item){  //跳转到sos定位位置
        var params = {
            Uids: item.sos_from_id, //再线成员用户id
            CorrdinateType: "Wgs84"
        };
        axios
        .post(
          beasconfig.gpsUrlapi + "/shanli/gps/api/locations/LastLocation?",
          qs.stringify(params),
          {
            headers: beasconfig.tokenSl
          }
        ).then(res =>{
          let data = res.data.Data;
          if (item.sos_location_at || data.length > 0) {
            if(this.$store.state.mapMarkerIndex == 1){  //判断是否选择的查看用户定位
              this.$store.state.jumpSosMark = [{ id: item.sos_from_id }];
            }
            EventBus.$emit("sosMarkerGps",item.sos_msg_id);
            this.$store.state.mapMarkerIndex = 0;
            this.$router.push(`/intercom/baiduMap`);
          }else {
            this.$message({
                message: this.$t('message.Nolocationtrack'),
                type: "warning",
                center: true,
                offset: 200
            });
          }
        })
    },
    handleSos(row,index){    //处理SOS
      if(this.$store.state.ws){
        console.log(this.$store.state.userInfo.User_ID, row.sos_msg_id)
        this.$store.state.ws.sosCloseAlarm(this.$store.state.userInfo.User_ID, row.sos_msg_id);
        this.sosList.splice(index,1);
        EventBus.$emit("handleSosMarker",row.sos_msg_id);
        this.$message({
            message: this.$t('message.message.Successfullyprocessed'),
            type: "warning",
            center: true,
            offset: 200
        });
        if(this.sosList.length === 0){
            this.suspendPlay();
        }
      }
    },
    selectedTab(index){
      this.alarmArrIndex = index;
    },
    getTransboundList:_debounce(function(){
        let that = this;
        let params = {
            companyId: parseInt(that.$store.state.userInfo.User_CompanyID),
            ddyId:parseInt(that.$store.state.userInfo.User_ID),
            pageNum:1,
            pageSize:8
        };
        axios.get(`${beasconfig.enclosureUrlapi}/alarm/transbound`,{params})
        // axios.get(`/liang/electronicFence/list`,{params})
          .then(res => {
            console.log('围栏列表', res);
            that.fenceList = res.data.data.rows;
          }).catch(console.error)
    },1000),
    getQuerySOSRecord(){   //sos记录
            let that = this;
            that.sosList = [];
           let into = (num)=>{
              // console.log(that.fenceList.length,that.sosTotal,1111)
              if(that.sosList.length < that.sosTotal || that.sosList.length === 0){
                let data = {
                  uId:that.$store.state.userInfo.id,
                  pageNo:num == 0?1:num,
                  pageSize:20,
                  status:0
                }
                querySOSRecord(data).then(res =>{
                      if(res.data.code == 200){
                                that.sosTotal = res.data.data.count;
                                let list = res.data.data.list;
                                that.sosList = that.sosList.concat(list);
                                if(that.sosList.length > 0){
                                  that.isOpen = true;
                                  into (num+1);
                                }
                        }else {
                            that.$MessageWarning(res.data.msg);
                        }
                        
                })
              }
            }
            into (0);
    },
    cloneWarning(){
      if(this.sosList.length > 0){
        this.isOpen = true;
        this.$MessageWarning(this.$t('message.UnhandledSOSexists'));
      }else{
        this.isOpen = false;
      }
    },
    notice(){   //查看最近8条警告记录
        this.isOpen = !this.isOpen;
    },
    seeOssPosition() {
      //查看oss
      let row = this.$store.state.sosPushInfo[this.$store.state.sosIndex];
      this.sosUser = {
        // sos_location_at: JSON.stringify(row.sosContent.sosLocationAt),
        sendName: row.sosContent.sosSendName,
        sos_start_time: this.$moment(row.sosContent.sosStamp).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        Cg_Name: row.sosContent.cgName,
        sos_msg_id: row.sosContent.sosMsgId,
        sos_from_id:row.sosContent.sosFromId,
        sos_status: row.sosContent.sosStatus
      };
      if(row.sosContent.sosLocationAt){
        this.sosUser.sos_location_at = JSON.stringify(row.sosContent.sosLocationAt);
      }
      this.$store.state.sosCallthepolice = false;
      this.$store.state.sosPushInfo.splice(this.$store.state.sosIndex, 1);
      this.$store.state.calltThePoliceType = 1;
      if(this.$store.state.enclosureTc.length > 0){
        this.giveanalarmType = 2;
      }
      this.suspendPlay();
      this.$router.push(
        `/history/sosPosition?sosUser=${JSON.stringify(this.sosUser)}`
      );
    },
    seeEchoList(type){    //查看视频
      if(type == 0){
        this.$router.push('/promotion/videoInfo');
        setTimeout(()=>{
          EventBus.$emit('switchVideo',1);
        },500)
      }
      this.$store.state.videoCallInfo.echoList = [];
    },
    listenInGb(){   //收听广播
          //  if(this.$store.state.isBroadcast){
          //     return
          //  }
           this.handleStop();
           this.suspendPlay();
           this.$store.state.isBroadcast = true;
           this.$store.state.calltThePolice = new Audio(
              `${beasconfig.mmsUrlapi}-${this.userState.server}/api/v1/media/download?user=${this.$store.state.gbInfoVoice[0].from}&uuid=${this.$store.state.gbInfoVoice[0].uuid}`
            );
            this.$store.state.calltThePolice.pause();
            setTimeout(()=>{
              this.$store.state.calltThePolice.play();
              this.$store.state.gbInfoVoice.splice(0,1);
                this.$store.state.calltThePolice.addEventListener("ended", () => {
                  //语音播放结束
                  this.$store.state.isBroadcast = false;
                  this.$store.state.calltThePolice = null;
                  if(this.$store.state.sosPushInfo > 0){
                    this.giveanalarmType = 1;   //播放sos
                    this.playWav();
                  }
                });
            },500)
            this.hangup();
    },
    hangup(){   //挂断视频
      if(this.$route.name == "videoInfo" && this.$store.state.videoCallInfo.callType > 0){
          EventBus.$emit("gdVideoCall",0);
      }
      if(this.$store.state.voiceCallInfo.callType > 0){
        if(this.$store.state.voiceCallInfo.isAnswerVoice){
          EventBus.$emit('sendRespond',0,0,1);
        }else{
          EventBus.$emit('sendRespond',0,0);
          EventBus.$emit('closeConversation');
        }
      }
    },
    next() {
      //下一条
      // this.suspendPlay();
      this.$store.state.calltThePoliceType = 0;
      // this.$store.state.sosCallthepolice = false;
      this.$store.state.sosPushInfo.splice(this.$store.state.sosIndex, 1);
    },
    PTT(type) {
      //讲话按键
      if (this.$store.state.isBroadcast) {
        return;
      }
      if (this.$store.state.pttIntercom && !this.visible) {  //判断点击ptt的时候是否在按空格
        
        return;
      }
      if (type == 1) {
        if(this.$store.state.voiceCallInfo.callType != 0){
              this.$MessageWarning('正在语音通话！');
          return
        }
        if(this.$store.state.videoCallInfo.callType != 0){
          this.$MessageWarning('正在视频呼叫！');
          return
        }
        this.$store.state.pttIntercom = true;
        EventBus.$emit("pptJh", 1);
        this.visible = true;
      } else {
        EventBus.$emit("pptJh", 2);
        this.visible = false;
        this.$store.state.pttIntercom = false;
      }
    },
    getNearly7DaysOnline() {
      //七天用户数量对比
      let that = this;
      let data = {
        uId: this.$store.state.userInfo.id
      };
      getNearly7DaysOnline(data).then(res => {
        var array = res.data.data.map(item => item.count); //遍历出数值
        let max = Math.max(...array);
        res.data.data.forEach(item => {
          item.days = that.$moment(item.date).format("MM-DD");
          item.percentage = max > 0 ? Math.abs((item.count / max) * 100) : 0;
        });
        that.sevenDaysData = res.data.data.reverse();
      });
    },
    getAlarmLogCount:_throttle(function(){
         //告警数统计
      let that = this;
      getAlarmLogCount({
        uId: this.$store.state.userInfo.id
      }).then(res => {
        let data = res.data.data;
        that.statistics.enclosureCount = data.enclosureCount;
        that.statistics.sosCount = data.sosCount;
      });
    },1000),
    closeSos(type) {
      //关闭sos
      if (type == 1) {
        if(this.$store.state.enclosureTc.length > 0){
          this.giveanalarmType = 2;
        }
        this.$store.state.sosPushInfo = [];
        this.suspendPlay();
        this.$store.state.sosCallthepolice = false;
      } else if(type == 2){   //关闭围栏
        this.$store.state.enclosureTc = [];
        this.suspendPlay();
      } else {    //关闭广播
        // this.$store.state.gbInfoVoice = [];
        this.$store.state.gbInfoVoice.splice(0,1);
        this.$store.state.isBroadcast = false;
        this.suspendPlay();
      }
    },
    hzEcharts() {
      //绘制echarts
      let that = this;
      let myChart = that.$echarts.init(document.getElementById("main"));
      getAlarmByMonth({
        uId: that.$store.state.userInfo.id
      }).then(res => {
        // console.log(res)
        let data = res.data.data;
        let timeData = data.enclosureList.map(item => item.date);
        let enclosureList = data.enclosureList.map(item => item.count); //围栏预警
        let sosList = data.sosList.map(item => item.count); //sos预警
        myChart.setOption({
          xAxis: {
            show: false,
            data: timeData,
            boundaryGap: false,
            axisLine: {
              show: true,
              lineStyle: {
                type: "solid",
                color: "#2776c3",
                width: "1"
              }
            }
          },
          grid: {
            left: 0,
            right: 25,
            bottom: 35,
            top: 30,
            containLabel: true
          },
          tooltip: {
            trigger: "axis"
          },
          yAxis: {
            axisLine: {
              show: false,
              lineStyle: {
                color: "#2776c3"
              }
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "solid",
                color: "#2776c3",
                width: "0.5"
              }
            },
            axisLabel: {
              formatter: "{value}"
            }
          },
          legend: {
            data: [that.$t('message.Fencewarning'), that.$t('message.SOSwarning')],
            textStyle: {
              color: "#fff"
            },
            top: 0,
            left: "3%"
          },
          series: [
            {
              name: that.$t('message.Fencewarning'),
              itemStyle: {
                normal: {
                  color: "yellow",
                  lineStyle: {
                    width: 2
                  }
                }
              },
              smooth: true,
              symbol: "line",
              type: "line",
              symbolSize: 2,
              data: enclosureList,
              animationDuration: 1000,
              animationEasing: "cubicInOut"
            },
            {
              name: that.$t('message.SOSwarning'),
              smooth: true,
              type: "line",
              symbol: "line",
              symbolSize: 2,
              itemStyle: {
                normal: {
                  color: "red",
                  lineStyle: {
                    width: 2
                  }
                }
              },
              data: sosList,
              animationDuration: 1000,
              animationEasing: "quadraticOut"
            }
          ]
        });
      });
    },
    xuanze(arr) {
      //排序
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i].num < arr[j].num) {
            var temp = arr[i];
            arr[i] = arr[j];
            arr[j] = temp;
          }
        }
      }
      return arr;
    },
    suspendPlay() {
      //sos暂停播放
      if (this.$store.state.calltThePolice != null) {
        this.$store.state.calltThePolice.pause();
        this.$store.state.calltThePolice = null;
        clearInterval(this.$store.state.calltThePoliceTimer);
      }
    },
    playWav: _throttle(function() {
      //sos播放语音
      if (
        this.$store.state.calltThePolice == null &&
        this.$store.state.calltThePoliceType == 0
      ) {
        if(this.giveanalarmType == 1){
            if(this.$store.state.voiceCallInfo.callType != 0){
                  
              return
            }
            if(this.$store.state.videoCallInfo.callType != 0){
              return
            }
            this.$store.state.calltThePoliceTimer = setInterval(() => {
              if(this.$store.state.sosPushInfo.length > 0){
                this.$store.state.calltThePolice = new Audio(sosWav);
                this.$store.state.calltThePolice.play();
              }
            }, 500);
        }else if(this.giveanalarmType == 2){
          if(!this.$store.state.isSpeech && this.userState.enclosureVoice == 1 && this.$store.state.gbInfoVoice.length <= 0 && this.$store.state.isBroadcast == false && this.$store.state.videoCallInfo.callType == 0 && this.$store.state.voiceCallInfo.callType == 0){
              this.$store.state.calltThePolice = new Audio(fence);
              this.$store.state.calltThePolice.play();
          }
          this.startTime();
        }
      }
    }, 500),
    suspendPlay1() {
      //广播结束
      if (this.$store.state.calltThePolice != null) {
        this.$store.state.calltThePolice.pause();
        this.$store.state.calltThePolice = null;
        this.$store.state.isBroadcast = false;
        clearInterval(this.$store.state.calltThePoliceTimer);
      }
    },
    playWav1: _throttle(function() {   //广播开始
      if (this.$store.state.calltThePolice == null && this.$store.state.gbInfoVoice.length > 0 && this.$store.state.isBroadcast == false) {
        this.$store.state.isBroadcast = true;
        clearInterval(this.$store.state.calltThePoliceTimer);
        this.$store.state.calltThePolice = new Audio(
          `${beasconfig.mmsUrlapi}-${this.userState.server}/api/v1/media/download?user=${this.$store.state.gbInfoVoice[0].from}&uuid=${this.$store.state.gbInfoVoice[0].uuid}`
        );
              this.$store.state.calltThePolice.play();
              this.$store.state.calltThePolice.addEventListener("ended", () => {
                //语音播放结束
                this.$store.state.gbInfoVoice.splice(0,1);
                this.$store.state.isBroadcast = false;
                this.$store.state.calltThePolice = null;
              });
      }
    }, 200),
    startTime() {
      //开始计时
      let that = this;
      // let num = 0;
      if(that.$store.state.calltThePoliceTimer1 != null){  //重复调用先清除定时器  2024-11-06目前改成了5秒只调用一次
          // clearTimeout(that.$store.state.calltThePoliceTimer1);
          return
      }
      if(that.$store.state.enclosureTc.length <= 0){    //围栏告警没有的时候不计时

        return
      }
      if(that.giveanalarmType == 2){
            if(that.$store.state.calltThePolice != null && that.$store.state.isBroadcast == false){   //播放语音广播的时候不播放围栏警告
              that.$store.state.calltThePolice.pause();
              that.$store.state.calltThePolice = null;
              clearInterval(that.$store.state.calltThePoliceTimer);
            }
            if(!that.$store.state.isSpeech && that.$store.state.enclosureTc.length > 0 && that.$store.state.isBroadcast == false && that.$store.state.voiceCallInfo.callType == 0 && that.$store.state.videoCallInfo.callType == 0){ //有语音广播的时候不播围栏警告
              if(that.userState.enclosureVoice == 1){   
                that.$store.state.calltThePolice = new Audio(fence);
                that.$store.state.calltThePolice.play();
              }
            }
            // that.startTime();
      }
      that.$store.state.calltThePoliceTimer1 = setTimeout(() => {
        // that.$store.state.enclosureTc.splice(0,1)

        that.$store.state.calltThePoliceTimer1 = null;
      }, 5000);
    },
    handleSpeak() {
      let gbInfoText = this.$store.state.gbInfoText;
      if(gbInfoText.length <= 0){
        
        return
      }
      this.textTips();
      if(this.isPlay){    //下一条不播放声音
        this.isPlay = false;
        return
      }
      if(this.$store.state.voiceCallInfo.callType != 0){

        return
      }
      if(this.$store.state.enclosureTc.length > 0 || this.$store.state.sosPushInfo.length > 0 && this.$store.state.calltThePolice != null || this.$store.state.videoCallInfo.callType != 0){   //文字广播优先级没有围栏告警高 有围栏告警的时候文字广播停止声音 sos同理

        return
      }
      window.speechSynthesis.cancel()
      if(this.$store.state.isBroadcast || this.$store.state.isSpeech){    //有语音广播并且正在讲话的时候不播放文字广播

        return
      }
      if(this.$store.state.enclosureTc.length > 0){   //播放的时候暂停围栏告警
        this.suspendPlay();
      }
      this.$store.state.isTextBb = true;    //暂停历史记录播放
      msg.text = gbInfoText[0].msg; // 文字内容: 小朋友，你是否有很多问号
      msg.lang = "zh-CN"; // 使用的语言:中文
      msg.volume = 5; // 声音音量：1
      msg.rate = 1; // 语速：1
      msg.pitch = 1; // 音高：1
      synth.cancel(msg);
      setTimeout(()=>{
        synth.speak(msg); // 播放
        this.$store.state.isTextBb = false;
        msg.onend = () => {
              //播放完成时间
              // if(this.$store.state.isTextBb){
                // this.$store.state.isTextBb = false;
                // this.$store.state.gbInfoText.splice(0,1);
                if(this.$store.state.sosPushInfo > 0){
                  this.giveanalarmType = 1;   //播放sos
                  this.playWav();
                }
              // }
        };
      },200)
    },
    textTips(){ //文字广播提示
          this.$message.closeAll();
          this.$message({
              iconClass: " ",
              dangerouslyUseHTMLString: true,
              message: `<div><img onclick="closeMaker(1)" class="mouse" style="width: 20px;height: 20px;" src="${textGb2x}" alt=""><div class="text" >${this.$store.state.gbInfoText[0].msg}</div> <img onclick="closeMaker(2)" class="mouse" style="width: 20px;height: 20px;" src="${textGb2x1}" alt=""></div>`,
              duration: 0,
              offset: 90,
              customClass: "textGb"
          });
    },
    handleStop(e) {
      //文字播报关闭
      msg.text = e;
      msg.lang = "zh-CN";
      msg.volume = 0; 
      synth.cancel(msg);
    },
    closeMaker(type) {
      //关闭message
      if(type == 1){
        if(this.$store.state.isSpeech || this.$store.state.isBroadcast || this.$store.state.enclosureTc.length > 0 || this.$store.state.sosPushInfo.length > 0 && this.$store.state.calltThePolice != null){   //文字广播优先级没有围栏告警高 有围栏告警的时候文字广播停止声音 sos同理

          return
        }
        this.suspendPlay();
        this.handleSpeak();
      }else{
        this.isPlay = true;   //点击下一条的时候不播放
        this.textTips();
        this.handleStop();
        this.$store.state.gbInfoText.splice(0,1);
        if(this.$store.state.gbInfoText.length <= 0){    //小于0的时候关闭
          this.$message.closeAll();
        }
      }
    }
  },
  watch: {
    "$store.state.groupOnline": {
      handler(newValue) {
        if (newValue.length > 0) {
          let that = this;
          let fixedGroup = newValue[0].GroupType.filter(
            item => item.GroupType == 1
          );
          let userTotal = newValue[0].onlineArray.filter(
            item => item.online == 3
          );
          that.statistics.fixedGroup = fixedGroup.length;
          that.statistics.userTotal = newValue[0].onlineArray.length;
          let num = JSON.stringify(userTotal.length).padStart(5, '0');
          let m = num.split('');
          that.statistics.onlineUserTotal = m;
          // that.statistics.onlineUserTotal = [
          // m[0] ? m[0] : 0,
          // m[1] ? m[1] : 0,
          // m[2] ? m[2] : 0,
          // m[3] ? m[3] : 0,
          // m[4] ? m[4] : 0,
          // ];
        }
      },
      // deep: true,
      immediate: true
    },
    "$store.state.groupUserData": {
      handler(newValue) {
        this.groupArray = this.xuanze([...newValue]).filter(item => item.GroupType != 2 && item.mmingroup != 1 || item.GroupType != 2 && item.mmingroup == 1 );
      },
      // deep: true,
      immediate: true
    },
    "$store.state.sosPushInfo"(newVal) {
      //对数据执行操作
      if (newVal.length > 0) {
        this.alarmArrIndex = 0;
        this.sosList.unshift(newVal[0]);
        this.isOpen = true;
        //满足条件直接触发sos告警 sos优先级最高
        if (this.userState.sosVoice == 1 && this.$store.state.calltThePoliceType == 0) {
          // this.$store.state.enclosureTc = [];
          this.$store.state.isBroadcast = false;
          this.giveanalarmType = 1;
          this.handleStop();
          this.suspendPlay();
          this.playWav();
        }
      }else{
        if(this.$store.state.calltThePoliceType == 1){
          this.suspendPlay();
        }
      }
    },
    "$store.state.enclosureTc"(newVal) {
      //对数据执行操作
      if (newVal.length > 0) {
          // 满足条件先暂停在播放围栏告警
          if(this.$store.state.calltThePolice == null && this.$store.state.sosPushInfo.length <= 0 && this.$store.state.isBroadcast== false){
            this.giveanalarmType = 2;
            this.handleStop();
            this.suspendPlay();
            this.playWav();
          }else{
            this.startTime();  //无声弹窗
          }
      }
    },
    "$store.state.gbInfoText"(newVal) {
      //对数据执行操作
      if (newVal.length > 0) {
        // this.textTips();
        this.handleSpeak();
      }
    },
    '$store.state.isSpeech'(newVal) {       //有人讲话
            //对数据执行操作
            if (newVal) {
                if(this.$store.state.enclosureTc.length > 0){
                  this.startTime();
                }
                if(this.$store.state.gbInfoText.length > 0){
                  this.handleStop();
                }
            }
    },
    "$store.state.videoCallInfo.callType"(type) {
            if (type != 0) {
              this.handleStop();
              this.suspendPlay();
            }
    },
    "$store.state.voiceCallInfo.callType"(type) {
            if (type != 0) {
              this.handleStop();
              this.suspendPlay();
            }
    }
    // "$store.state.gbInfoVoice"(newVal) {
    //   //对数据执行操作
    //   if (newVal.length > 0) {
    //     // this.$store.state.calltThePolice = null;
    //     // this.$store.state.sosPushInfo = [];
    //     // this.textTips();
    //     // if(!this.$store.state.isBroadcast && !this.$store.state.isTextBb){
    //     //   this.suspendPlay();
    //     // }
    //     // if (
    //     //   this.$store.state.gbInfo.length > 0 &&
    //     //   this.$store.state.gbInfo[0].msg_type == 3
    //     // ) {
    //     //   // this.playWav1();
    //     // } else {
    //     //   this.textTips();
    //     //   // if(!this.$store.state.isTextBb){
    //     //   //   this.handleSpeak();
    //     //   // }
    //     // }
    //   }
    // }
  }
};
</script>
<style lang="less">
.textGb {
  background-color: #fbfbde !important;
  color: #000000;

  .el-message__content>div {
    display: flex;
    align-items: center;

    .text {
      min-width: 380px;
      flex: 1;
      line-height: 20px;
      margin: 0px 5px;
    }
  }
}
</style>
<style lang="less" scoped>
::-webkit-scrollbar {
  width: 0px;
  height: 100%;
  background-color: #ccc;
}

::-webkit-scrollbar-track {
  width: 0px;
  background-color: #4d4d4d;
}

::-webkit-scrollbar-thumb {
  background-color: #727171;
}

.overall {
  height: 100%;
  position: absolute;
  top: 55px;
  // right: 10px;
  right: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // overflow: hidden;
  z-index: 15;

  .horizontal {
    display: flex;
    align-items: center;

    &_img {
      width: 120px;
      height: 54px;
    }

    .mian-body {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border: 1px solid #c2c2c2;
      border-radius: 4px;
    }

    .overall-horizontal {
      width: 190px;
      height: 40px;
      overflow: hidden;
      display: flex;
      align-items: center;
      background: #fff;
      margin-left: 8px;
      border: 1px solid #3b7777;
      border-radius: 5px;
      box-shadow: 0 0 5px 0px #3b7777;

      .icon {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #e2e2e2;

        &_bg {
          width: 25px;
          height: 25px;
        }

        .ace1 {
          background: url("../assets/map_zx.png");
          background-size: 100% 100%;
        }

        .ace2 {
          background: url("../assets/map_fh.png");
          background-size: 100% 100%;
        }

        .ace3 {
          background: url("../assets/map_sc.png");
          background-size: 100% 100%;
        }
      }
    }
  }

  .right {
    // width: 300px;
    width: 304px;
    // width: 292px;

    // height: 100%;
    // position: relative;
    // height: calc(100% - 64px);
    &_box {
      // width: 96%;
      padding: 0px 10px;
      margin: 0px auto;
    }

    &_border {
      border: 1px solid var(--main-color);
      background-color: #343434;
      opacity: 0.9;
      box-shadow: 0px 0px 5px 0px var(--main-color);
      border-radius: 5px;
      margin-bottom: 5px;
    }

    &_Statistics {
      width: 100%;
      height: 157px;
      position: relative;
    }

    &_title {
      width: 100%;
      // height: 157px;
      font-size: 15px;
      padding: 10px 0px;
      color: #fff;
      text-align: left;
    }

    &_nuber {
      width: 100%;
      height: 30px;
      display: flex;
      align-items: baseline;
      color: #fff;
      margin-top: 10px;
    }

    &_bg {
      width: 20px;
      height: 31px;
      line-height: 31px;
      text-align: center;
      font-size: 30px;
      font-weight: bold;
      background-color: rgba(255, 255, 255, 0.2);
      color: #fff;
      font-family: fangsong;
      margin-right: 10px;
    }

    &_bottom {
      width: 100%;
      position: absolute;
      bottom: 10px;
      left: 0px;
      color: #fff;

      &_box {
        // width: 25%;
        flex: 1;
        text-align: center;
      }

      &_title {
        margin-bottom: 5px;
        font-size: 13px !important;
      }

      &_num {
        font-size: 20px !important;
        color: var(--main-color-tow);
      }
    }

    &_customer {
      width: 100%;
      //height: calc(100vh - 320px - 180px);
      height: calc(100vh - 380px - 157px - 54px - 110px);
      //height: calc(100vh - 380px - 20px - 110px);
    }

    &_container {
      width: 100%;
      //height: calc(100vh - 320px - 180px);
      //height: calc(100vh - 380px - 157px - 54px - 110px);
      height: calc(100vh - 380px - 157px - 54px - 110px - 60px);
      overflow: auto;
    }

    &_container_tow {
      width: 100%;
      //height: calc(100vh - 380px - 180px);
      height: calc(100vh - 380px - 157px - 54px - 110px - 60px);
      overflow: auto;
    }

    &_name {
      width: 100%;
      color: #fff;
      font-size: 12px;
      margin-bottom: 10px;
    }

    &_dub {
      width: 100%;
      color: #fff;
      font-size: 12px;
      margin-bottom: 4px;

      /deep/.el-progress-bar__inner {
        background-image: linear-gradient(90deg,
            rgba(106, 181, 253, 0.2) 35%,
            rgba(106, 181, 253, 0.8) 65%) !important;
      }
    }

    &_num_color {
      color: var(--main-color-tow);
    }

    &_num_bg {
      width: 16px;
      height: 16px;
      line-height: 16px;
      text-align: center;
      border-radius: 50%;
    }

    &_flex {
      flex: 1;
      text-align: left;
      padding: 0px 10px;
    }

    &_contrast {
      width: 100%;
      height: 190px;
    }

    &_tian {
      width: 38px;
      font-size: 12px;
    }

    .bg_one {
      background-color: rgba(106, 181, 253, 0.8);
    }

    .bg_tow {
      background-color: rgba(106, 181, 253, 0.6);
    }

    .bg_three {
      background-color: rgba(106, 181, 253, 0.4);
    }

    .bg_four {
      background-color: rgba(106, 181, 253, 0.2);
    }
  }
}

.overall_one {
  top: 5px !important;
}

.bottom {
  width: 100%;
  position: absolute;
  bottom: 5px;
  left: 0;
  display: flex;
  align-items: flex-end;

  &_left {
    width: 180px;
    position: fixed;
    bottom: 0px;
    left: 55%;
    z-index: 100;
    text-align: center;

    .triangle {
      width: 0px;
      border-style: solid;
      border-color: transparent;
      border-width: 8px 8px 8px 8px;
      border-top-color: #414447;
      opacity: 1;
      margin: auto;
      position: relative;

      &_border {
        width: 0px;
        border-style: solid;
        border-color: transparent;
        border-width: 9px 9px 9px 9px;
        border-top-color: var(--main-color);
        position: absolute;
        top: -8px;
        left: -9px;
        z-index: -1;
      }
    }

    .bottom_ptt {
      width: 107px;
      height: 107px;
      margin: auto;
    }
  }

  &_popup {
    width: 180px;
    height: 40px;
    line-height: 40px;
    padding: 0px 10px;
    text-align: center;
    position: relative;
    background-color: #414447;
    opacity: 0.9;
    border-radius: 5px;
    border: 1px solid var(--main-color);
    top: -15px;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #ffffff;
    margin-left: -7%;
  }

  &_tz {
    width: 48px;
    height: 48px;
    position: absolute;
    bottom: 20px;
    right: 100px;
    z-index: 10;
  }
}

.sos {
  width: 395px;
  // height: 335px;
  max-height: 335px;
  // width: 315px;
  // height: 260px;
  background: url("../assets/sos/sos_tck.png");
  background-size: 100% 100%;
  position: fixed;
  right: 7px;
  bottom: 12px;
  color: #fff;
  z-index: 16;

  &_head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 15px;
  }

  &_gb {
    width: 26px;
    height: 26px;
    position: absolute;
    right: 12px;
    top: 35px;
    // top: 25px;
  }

  &_bottom {
    width: 100%;
    justify-content: center;
  }

  &_Circular {
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    background-color: red;
    border: 1px solid #ffffff;
    position: absolute;
    font-size: 12px;
    right: 158px;
    top: 0px;
    z-index: 8;
  }

  &_content {
    width: 90%;
    flex: 1;
    // height: 140px;
    margin: 30px auto;
    text-align: left;
    font-size: 18px;
    color: #f2f2f2;

    span {
      font-weight: bold;
    }

    &_sl {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &_address {
      width: 100%;
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &_but {
    width: 135px;
    height: 45px;
    line-height: 45px;
    // background-color: #48d3d4;
    border-radius: 50px;
  }
}

.giveAnAlarm {
  width: 300px;
  height: 300px;
  // max-height: 335px;
  background-color: rgba(0, 0, 0, 0.8);
  // opacity: 0.75;
  position: fixed;
  right: 106px;
  bottom: 32px;
  color: #f8f8f8;
  border-radius: 10px;
  border: 1px solid red;
  z-index: 11;

  &_gb {
    position: absolute;
    right: 8px;
    top: 2px;
    font-size: 25px;
  }

  &_title {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    letter-spacing: 3px;
  }

  &_tab {
    width: 100%;
    height: 32px;
    border-bottom: 1px solid #000000;


    .active:after {
      content: "";
      position: absolute;
      bottom: -3px;
      left: 0;
      right: 0;
      // height: 2px;
      /* 下划线的高度 */
      // background-color: #FF0000;
      /* 下划线的颜色 */
      border-bottom: 1px solid #FF0000;
      /* 可选：为下划线增加一个边框 */
    }

    &_item {
      height: 28px;
      position: relative;

      .red {
        font-weight: bold;
        color: #FF0000;
      }

      &_box {
        width: 120px;
        height: 100%;
        line-height: 28px;
        font-size: 14px;
        background-color: #000000;
        border-radius: 20px;
        margin: auto;
        text-indent: 13px;

        &_bg {
          width: 40px;
          height: 100%;
          // background: url(../assets/sos/alarm_yj1.png);
          background-repeat: no-repeat;
          /* 设置背景图片不重复 */
          // background-position: left; /* 设置背景图片左对齐 */
        }

        .alarm1 {
          background-position: left;
          /* 设置背景图片左对齐 */
        }

        .alarm {
          background-position: center;
          /* 设置背景图片居中 */
          background-position-x: 5px;
        }

      }
    }
  }

  &_info {
    flex: 1;
    height: calc(300px - 70px - 30px);
    margin: 15px 0px 15px 15px;
    text-align: left;
    font-size: 14px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 6px;
      height: 100%;
      background-color: #ccc !important;
      border-radius: 4px 4px 4px 4px;
    }

    &::-webkit-scrollbar-track {
      width: 6px;
      margin-left: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ffffff !important;
    }

    &_Serial {
      width: 28px;
      height: 16px;
      line-height: 16px;
      border-radius: 20px;
      background-color: #FF0000;
      color: #ffffff;
      text-align: center;
      margin-right: 8px;
      padding: 0px 0px;
      font-size: 12px;
    }

    &>div {
      width: 100%;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
      margin-bottom: 5px;
    }

    &_one {
      width: 55px;
    }

    &_cl {
      min-width: 40px;
      height: 20px;
      padding: 0px 5px;
      background: #FF0000;
      border-radius: 4px 4px 4px 4px;
      text-align: center;
    }

    &_jl {
      width: 119px;
      height: 18px;
      background: #2776C3;
      border-radius: 19px 19px 19px 19px;
      font-weight: 400;
      font-size: 12px;
      margin: 0px auto 6px auto;
    }
  }

  &_tips {
    font-size: 12px;
    color: #ccc;
    margin-bottom: 5px;
  }
}

.customer_height {
  //height: calc(100vh - 270px - 180px);
  height: calc(100vh - 380px - 163px - 110px);
}

.flex_align {
  display: flex;
  align-items: center;
}
</style>
