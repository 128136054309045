<template>
    <!-- <div class="box flex"> -->
    <el-dialog :title="$t('message.Changepassword')" v-if="dialogModifyPasswode" :append-to-body="true" :visible.sync="dialogModifyPasswode" width="750px"
        :before-close="handleClose" center>
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
            <div class="content">
                <div style="height:40px;"></div>
                <el-form-item :label="$t('message.OldPassword') +' :'" prop="usedPassword">
                    <!--  -->
                    <el-input type="password" @focus="focus" @blur="blur" :placeholder="$t('message.message.Pleaseenteroldpassword')"
                        v-model="ruleForm.usedPassword"></el-input>
                    <!-- autocomplete="off" -->
                    <div class="content_text"></div>
                </el-form-item>
                <el-form-item :label="$t('message.NewPassword') +' :'" prop="newPassword">
                    <!--  -->
                    <el-input type="password" @focus="focus" @blur="blur" :placeholder="$t('message.message.Pleaseenteranewpassword')"
                        v-model="ruleForm.newPassword"></el-input>
                    <div class="content_text">*{{ $t('message.consists') }}</div>
                </el-form-item>
                <el-form-item :label="$t('message.ConfirmPassword') +' :'" prop="determinePassword">
                    <!--  -->
                    <el-input type="password" @focus="focus" @blur="blur" :placeholder="$t('message.message.Confirmnewpassword')"
                        v-model="ruleForm.determinePassword"></el-input>
                    <div class="content_text"></div>
                </el-form-item>
            </div>
            <div class="bottom">
                <el-button class="bc" @click="submitForm('ruleForm')">{{$t('message.Save')}}</el-button>
                <el-button class="gb" @click="resetForm('ruleForm')">{{$t('message.Closure')}}</el-button>
            </div>
        </el-form>
    </el-dialog>
    <!-- </div> -->
</template>

<script>
import regfunc from "../method/form/regfunc.js";
import EventBus from "@/eventBus/eventBus.js";
import { updatePassword } from "@/administration/login.js";
export default {
    props: {
        // dialogModifyPasswode: {
        //     type:Boolean,
        //     default:true
        // } 
    },
    data() {
        var usedPassword = (rule, value, callback) => {     //旧密码
            if (value == "") {
                callback(new Error(this.$t('message.message.Pleaseenteroldpassword')));
            } else {
                callback();
            }
        };
        var newPassword = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('message.message.Pleaseenteranewpassword')));
            } else if (!regfunc.rPassword.test(value)) {
                callback(new Error(`${this.$t('message.consists')}`));
            } else if (this.ruleForm.usedPassword === value) {
                callback(new Error(this.$t('message.message.noSamePassword')));
            } else {
                callback();
            }
        };
        var determinePassword = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('message.message.pleaseenterpasswordagain')));
            } else if (value !== this.ruleForm.newPassword) {
                callback(new Error(this.$t('message.message.noSamePasswordTow')));
            } else {
                callback();
            }
        };
        return {
            ruleForm: {
                usedPassword: '',       //旧密码
                newPassword: '',        //新密码
                determinePassword: ''   //确定密码
            },
            rules: {
                usedPassword: [
                    { validator: usedPassword, trigger: 'blur' }
                ],
                newPassword: [
                    { validator: newPassword, trigger: 'blur' }
                ],
                determinePassword: [
                    { validator: determinePassword, trigger: 'blur' }
                ]
            },
            dialogModifyPasswode: false
        };
    },
    created() {

    },
    beforeCreate: function () { },
    methods: {
        openKq() {       //开启
            this.dialogModifyPasswode = true;
        },
        handleClose() {     //关闭
            this.dialogModifyPasswode = false;
        },
        submitForm(formName) {      //修改密码
            let that = this;
            let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
            that.$refs[formName].validate((valid) => {
                if (valid) {
                    let data = {
                        uId: userInfo.id,
                        oldPassword: that.ruleForm.usedPassword,
                        password: that.ruleForm.newPassword
                    }
                    updatePassword(data).then(res => {
                        if (res.data.code == 200) {
                            // localStorage.removeItem("userNumber");
                            sessionStorage.removeItem("userInfo");
                            localStorage.removeItem("userNumber");
                            // this.$router.push("/Login");
                            EventBus.$emit('changePassword');
                            that.dialogModifyPasswode = false;
                            that.$MessageSuccess(that.$t('message.message.pleaseenterpasswordagain'));
                        }else if(res.data.code == 4002){
                            that.$message.error(that.$t('message.message.Parametererror'));
                        }else if(res.data.code == 4003){
                            that.$message.error(that.$t('message.message.oldpassworderror'));
                        } else {
                            that.$message.error(res.data.msg);
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.dialogModifyPasswode = false;
        },
        focus() {    //获取焦点
            this.$store.state.isFocusing = true;
        },
        blur() {     //失去焦点
            this.$store.state.isFocusing = false;
        }
    },
    mounted() {

    },
    watch: {
        //监听事件
        dialogModifyPasswode(show) {
            if (show) {
                this.ruleForm = {
                    usedPassword: '',       //旧密码
                    newPassword: '',        //新密码
                    determinePassword: ''   //确定密码
                }
            }
        }
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
/deep/.el-dialog {
    background: #4D4D4D;
    margin-top: 20vh !important;

    &__title {
        color: #fff;
        font-size: 16px;
    }

    .el-dialog__headerbtn .el-dialog__close {
        color: var(--main-color) !important;
    }



    .el-input__inner {
        width: 100%;
        height: 35px;
        background-color: #4D4D4D;
        border: 1px solid #4D4D4D;
        color: #fff;
    }

}

/deep/.el-input {
    width: 225px;
}

/deep/.gb.el-button {
    width: 89px;
    height: 38px;
    border: 1px solid #fff !important;
    background-color: transparent !important;
    color: #fff !important;
}

/deep/.bc.el-button {
    width: 89px;
    height: 38px;
    border: 1px solid var(--main-color) !important;
    background-color: transparent !important;
    color: var(--main-color) !important;
    margin-right: 30px;
}

/deep/.el-form-item__error {
    padding-top: 0px !important;
}

.content {
    width: 90%;
    height: 251px;
    margin: 0px auto 0px auto;
    // text-align: center;
    background-color: #383838;
    display: flex;
    flex-direction: column;
    align-items: center;

    &_text {
        display: inline-block;
        // width: 150px;
        color: red;
        font-size: 12px;
        margin-left: 10px;
    }

    /deep/.el-form-item {
        width: 90%;
        margin-bottom: 15px;

        &__label {
            text-align: right;
            color: #fff !important;
        }
    }
}

.bottom {
    width: 100%;
    text-align: center;
    padding: 15px 0px;
}

/deep/.el-dialog--center .el-dialog__body {
    padding: 0px !important;
}

.flex {
    display: flex;
    align-items: center;
}
</style>