/*
 * @Author: lbw
 * @Date: 2022-09-17 15:14:38
 * @LastEditors: lbw
 * @LastEditTime: 2022-09-17 16:43:22
 * @Description:
 */
import axios from "axios";
import beasconfig from "./beasconfig.js";

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: beasconfig.xinextendUrlapi, //测试
  timeout: 600000
});

export default service;
