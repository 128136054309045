<template>
    <el-dialog :title="$t('message.selectionPeriod')" :append-to-body="true" :before-close="gbDialog"
        width="600px" :visible.sync="dialogTableVisible" center>
        <!-- 选择时间 -->
        <div class="dialog flex_direction" style="height: 165px;">
            <div class="dialog_block dialog_text flex">
                <div class="demonstration">{{ $t('message.startingtime1') }} : </div>
                <el-date-picker v-model="timeData.startTime" type="datetime" :picker-options="pickerOptions"
                    :placeholder="$t('message.Pleaseselectadateandtime')">
                </el-date-picker>
            </div>
            <div class="dialog_block dialog_text flex">
                <div class="demonstration">{{ $t('message.EndTime') }} : </div>
                <el-date-picker v-model="timeData.endTime" type="datetime" :picker-options="pickerOptions"
                    :placeholder="$t('message.Pleaseselectanendtime')">
                </el-date-picker>
            </div>
        </div>
        <!-- 选择时间结束 -->
        <div class="bottom">
            <el-button class="bc" @click="getSendOut()" @keyup.prevent @keydown.enter.prevent>{{ $t('message.Sure') }}
            </el-button>
            <el-button class="gb" @click="gbDialog()">{{ $t('message.Closure') }}</el-button>
        </div>
    </el-dialog>
</template>

<script>
import moment from 'moment';
export default {
    props: {
        dialogTitle: {
            type: String,
            default: ''
        },
        butName: {       //按钮名称
            type: String,
            default: '发送'
        },
        cancelBut: {       //取消按钮
            type: String,
            default: '取消'
        },
        dialogData: {       //对话框数据
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            userInfo: {},
            pickerOptions: {
                disabledDate(time) {
                    let curDate = (new Date()).getTime();
                    let three = 90 * 24 * 3600 * 1000;
                    let threeMonths = curDate - three;
                    return time.getTime() > Date.now() || time.getTime() < threeMonths;
                }
            },
            dialogTableVisible: true,
            timeData: {
                startTime: '',       //开始时间
                endTime: ''          //结束时间
            }
        };
    },
    created() {

    },
    beforeCreate: function () { },
    methods: {
        // 关闭对话框
        gbDialog() {
            this.$emit('closeUsertrajectory');
        },
        getSendOut() {       //发送
            let timeData = this.timeData;
            var tiem1 = moment(timeData.startTime).format("YYYY-MM-DD");
            var tiem2 = moment(timeData.endTime).format("YYYY-MM-DD");
            var value1 = moment(this.timeData.startTime).valueOf();
            var value2 = moment(this.timeData.endTime).valueOf();
            if (timeData.startTime == "" || timeData.startTime == null || timeData.endTime == "" || timeData.endTime == null) {
                this.$MessageWarning(this.$t('message.message.Pleaseselectastartandendtime'));
                return
            }
            if (value1 > value2) {
                this.$MessageWarning(this.$t('message.message.endTimeNoStarttime'));
                return
            } else if (tiem1 == tiem2) {
                this.$emit('successUsertrajectory');
                var vv1 = moment(this.timeData.startTime).format("YYYY-MM-DD HH:mm:ss");
                var vv2 = moment(this.timeData.endTime).format("YYYY-MM-DD HH:mm:ss");
                let isOrientation = this.dialogData.services ? this.dialogData.services.gps : false;  //判断是否开启了gps 未开启的话就使用基站服务
                this.$router.push(`/intercom/trajectory?tiem1=${value1}&tiem2=${value2}&vv1=${vv1}&vv2=${vv2}&uid=${this.dialogData.id}&isOrientation=${isOrientation}`);
                this.timeData = {
                    startTime: '',
                    endTime: ''
                }
            } else {
                this.$MessageWarning(this.$t('message.message.Thestartandendtimesmustbeonthesameday'));
            }
        }
    },
    mounted() {
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    },
    filters: {

    },
    watch: {

    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.el-dialog__wrapper {
    display: flex;
    align-items: center;
}


/deep/.el-dialog {
    background: #4D4D4D;
    margin-top: -10vh !important;

    &__title {
        color: #fff;
        font-size: 16px;
    }

    .el-dialog__headerbtn .el-dialog__close {
        color: var(--main-color-tow) !important;
    }

    .dialog {
        width: 94%;
        background-color: #383838;
        margin: auto;
        color: #fff;
        border: 1px solid #6e6d6d;

        &_block {
            margin-bottom: 15px;

            .el-input--prefix .el-input__inner {
                padding-left: 15px;
            }

            .el-icon-date:before {
                display: none;
            }

            .el-input__inner {
                width: 100%;
                height: 35px;
                background-color: #4D4D4D;
                border: 1px solid #4D4D4D;
                color: #fff;
            }
        }

        &_text {
            font-size: 14px;
        }

    }

}


/deep/.el-input.is-disabled .el-input__inner {
    background: transparent;
}

/deep/.el-input__icon {
    display: none;
}

.search_one {
    /deep/.el-input__inner {
        width: 100%;
        // width: 100%;
        height: 28px;
        line-height: 28px;
        background-color: #333333;
        color: #ffffff;
        border: none;
        border-radius: 0px;
        padding: 0px 10px;
    }
}

.bottom {
    width: 100%;
    text-align: center;
    padding: 15px 0px;

    /deep/.gb.el-button {
        width: 89px;
        height: 38px;
        border: 1px solid #fff !important;
        background-color: transparent !important;
        color: #fff !important;
    }

    /deep/.bc.el-button {
        width: 89px;
        height: 38px;
        border: 1px solid var(--main-color-tow) !important;
        background-color: transparent !important;
        color: var(--main-color-tow) !important;
        margin-right: 30px;
    }
}



/deep/.el-dialog--center .el-dialog__body {
    padding: 0px !important;
}



.flex {
    display: flex;
    align-items: center;
}

.flex_between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex_direction {
    //垂直居中
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.demonstration {
    width: 120px;
}
</style>