import request from '@/api/request'

// 获取用户信息
export async function getUserInfo(data) {
    return await request({
        url: '/intercom/getUserInfo',
        method: 'post',
        data: data
    })
}

// 修改用户信息
export async function updateUserInfo(data){
    return await request({
        url:'/intercom/updateUserInfo',
        method:'post',
        data:data
    })
}


// 七天用户数对比
export async function getNearly7DaysOnline(data){
    return await request({
        url:'/census/getNearly7DaysOnline',
        method:'post',
        data:data
    })
}

// 获取成员服务
export async function queryUserService(data){
    return await request({
        url:'/user/queryUserService',
        method:'post',
        data:data
    })
}

// 近30天告警对比
export async function getAlarmByMonth(data){
    return await request({
        url:'/census/getAlarmByMonth',
        method:'post',
        data:data
    })
}

// 告警数统计
export async function getAlarmLogCount(data){
    return await request({
        url:'/census/getAlarmLogCount',
        method:'post',
        data:data
    })
}

// 监听
export async function queryMonitorGroup(data){
    return await request({
        url:'user/queryMonitorGroup',
        method:'post',
        data:data
    })
}

// 添加监听 
export async function joinMonitorGroup(data){
    return await request({
        url:'user/joinMonitorGroup',
        method:'post',
        data:data
    })
}

// 删除监听 
export async function removeMonitorGroup(data){
    return await request({
        url:'user/removeMonitorGroup',
        method:'post',
        data:data
    })
}

// 修改用户状态 
export async function updateUserStatus(data){
    return await request({
        url:'user/updateUserStatus',
        method:'post',
        data:data
    })
}

// 修改群组名称 
export async function updateGroup(data){
    return await request({
        url:'group/updateGroup',
        method:'post',
        data:data
    })
}

// 查询群组成员
export async function queryMember(data){
    return await request({
        url:'group/queryMember',
        method:'post',
        data:data
    })
}

// 查询不在组成员
export async function queryUnMember(data){
    return await request({
        url:'group/queryUnMember',
        method:'post',
        data:data
    })
}

// 移除群组成员
export async function removeMember(data){
    return await request({
        url:'group/removeMember',
        method:'post',
        data:data
    })
}

// 添加群组成员
export async function addMember(data){
    return await request({
        url:'group/addMember',
        method:'post',
        data:data
    })
}

// 修改用户优先级
export async function updatePriorities(data){
    return await request({
        url:'group/updatePriorities',
        method:'post',
        data:data
    })
}

// 修改主持人
export async function updaetHost(data){
    return await request({
        url:'group/updaetHost',
        method:'post',
        data:data
    })
}


// 查询群组详情
export async function queryGroup(data){
    return await request({
        url:'group/queryGroup',
        method:'post',
        data:data
    })
}


// 近8条通知
export async function getRecordFirstB(data){        //近8条通知
    return await request({
        url:'census/getRecordFirst8',
        method:'post',
        data:data
    })
}

// 查询单位用户（创建区域）
export async function queryUserByRegion(data){       
    return await request({
        url:'region/queryUserByRegion',
        method:'post',
        data:data
    })
}

//  查询在组（区域群组）
export async function queryRegionMember(data){       
    return await request({
        url:'region/queryMember',
        method:'post',
        data:data
    })
}

// 查询不在组（区域群组）
export async function queryRegionUnMember(data){       
    return await request({
        url:'region/queryUnMember',
        method:'post',
        data:data
    })
}

//查询区域id
export async function getRegionId(data){       
    return await request({
        url:'region/getRegionId',
        method:'post',
        data:data
    })
}

//创建群组
export async function addGroup(data){       
    return await request({
        url:'group/addGroup',
        method:'post',
        data:data
    })
}