import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
// import About from "../views/About.vue";
import enclosure from "../router/enclosure.js"; //电子围栏
import intercom from "../router/intercom.js"; //调度对讲
import promotion from "../router/promotion.js"; //历史记录
import history from "../router/history.js"; //系统管理
import Refresh from "../components/Refresh.vue"; //刷新
// import Login from "../views/Login.vue"  //登录页面

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    meta: { title: "芯对讲调度台" },
    component: () => import("../views/Login.vue"),
    redirect: "Login"
  },
  {
    path: "/Login",
    name: "Login",
    meta: { title: "芯对讲调度台" },
    component: () => import("../views/Login.vue")
  },
  {
    path: "/dashboard", //主页
    name: "Home",
    meta: { title: "首页" },
    component: Home,
    children: [...intercom,...enclosure,...promotion,...history]
    // redirect:"/Home",
    // children: [
    //   {
    //     path: "/intercom",
    //     name: "intercom",
    //     meta: { title: "调度对讲" },
    //     component:About,
    //     children: [...intercom]
    //   },
    //   {
    //     path: "/enclosure",
    //     name: "enclosure",
    //     meta: { title: "电子围栏" },
    //     component:About,
    //     children: [...enclosure]
    //   },
    //   {
    //     path: "/promotion",
    //     name: "promotion",
    //     meta: { title: "实时视频" },
    //     component: About,
    //     children: [...promotion]
    //   },
    //   {
    //     path: "/history",
    //     name: "history",
    //     meta: { title: "历史记录" },
    //     component:About,
    //     children: [...history]
    //   }
    // ]
  },
  {
    path: "",
    component: Refresh
  }
];

const router = new VueRouter({
  mode: "hash",
  // base: process.env.BASE_URL,
  routes
  // scrollBehavior: () => {
  //   history.pushState(null, null, document.URL)
  // }
});

export default router;
