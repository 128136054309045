export default [    //电子围栏
    {   
        path: "/enclosure/TransboundaryRecord",  
        name:'TransboundaryRecord',
        meta: { title: "越界记录",navId:2 },
        component:() => import("@/views/enclosure/TransboundaryRecord.vue")
    },
    {   
        path: "/enclosure/enclosureMap",  
        name:'enclosureMap',
        meta: { navId:2 },
        component:() => import("@/views/enclosure/enclosureMap.vue")
    }
]

