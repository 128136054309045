import request from '@/api/request'

// .围栏成员及管理员选择(创建围栏)
export async function queryUserByEnclosure(data) {
    return await request({
        url: '/enclosure/queryUserByEnclosure',
        method: 'post',
        data: data
    })
}

// 查询围栏成员
export async function queryEnclosureMember(data) {
    return await request({
        url: '/enclosure/queryEnclosureMember',
        method: 'post',
        data: data
    })
}
// 查询非围栏成员
export async function queryUNEnclosureMember(data) {
    return await request({
        url: '/enclosure/queryUNEnclosureMember',
        method: 'post',
        data: data
    })
}


